import {
    Autocomplete,
    Box,
    Button,
    Divider,
    Grid,
    IconButton,
    InputAdornment,
    TextField,
    Typography,
    useTheme
} from "@mui/material";
import {useDispatch, useSelector} from "react-redux";
import {RootState} from "../../../store";
import {useNavigate} from "react-router-dom";
import hot from "../../../assets/images/hot.png"
import {Check, Close, Delete, Description, East, Edit, PersonRemove, Search} from "@mui/icons-material";
import notificationDelete from "../../../shared/notificationDelete";
import {projectComplete, projectCustomerCodeGet, projectDelete} from "../../project/project.api";
import {useEffect, useState} from "react";
import {getDashboardProject, getDashboardRequestProject} from "../dashboard.api";
import {formatName} from "../../../shared/utils";
import {getAccountFielder} from "../../account/account.api";
import Papa from "papaparse";
import moment from "moment/moment";
import ProjectCustomerCode from "../../project/component/shared/ProjectCustomerCode";

export default function DashboardProject(props: {
    dateStart: string,
    dateEnd: string,
    status?: number,
    isTotalFieldingRequest?: boolean,
    onSelect: (projectId: string) => void
}) {

    const theme = useTheme()
    const navigate = useNavigate()
    const dispatch = useDispatch<any>()

    const {projectList} = useSelector((state: RootState) => state.dashboard)
    const {dataFielderList} = useSelector((state: RootState) => state.account)

    const { dataCustomerCodeList } = useSelector((state: RootState) => state.project)

    const [fielderIdSelected, setFielderIdSelected] = useState<string | undefined>(undefined)
    const [customerIdSelected, setCustomerIdSelected] = useState<string | undefined>(undefined)
    const [filterArea, setFilterArea] = useState<string | undefined>("")
    const [filterCustomer, setFilterCustomer] = useState<string | undefined>("")

    useEffect(() => {
        dispatch(getAccountFielder())
        dispatch(projectCustomerCodeGet())
    }, [dispatch])

    useEffect(() => {
        if (props?.isTotalFieldingRequest) {
            dispatch(getDashboardRequestProject({
                filterStartDate: props.dateStart,
                filterEndData: props.dateEnd,
                status: props.status || 0
            }))
        } else {
            dispatch(getDashboardProject({
                filterStartDate: props.dateStart,
                filterEndData: props.dateEnd,
                status: props.status || 0,
                area: filterArea,
                customer: filterCustomer,
                assignedFielderId: fielderIdSelected
            }))
        }

    }, [props?.isTotalFieldingRequest])

    const handleDelete = (id: string) => {
        notificationDelete().then((isConfirmed) => {
            if (isConfirmed) {
                dispatch(projectDelete(id))
                    .unwrap()
                    .then(() => {
                        if (props?.isTotalFieldingRequest) {
                            dispatch(getDashboardRequestProject({
                                filterStartDate: props.dateStart,
                                filterEndData: props.dateEnd,
                                status: props.status || 0
                            }))
                        } else {
                            dispatch(getDashboardProject({
                                filterStartDate: props.dateStart,
                                filterEndData: props.dateEnd,
                                status: props.status || 0,
                                area: filterArea,
                                customer: filterCustomer,
                                assignedFielderId: fielderIdSelected
                            }))
                        }
                    })
            }
        })
    }

    const handleComplete = (projectId: string) => {
        dispatch(projectComplete(projectId))
            .unwrap()
            .then(() => {
                if (props?.isTotalFieldingRequest) {
                    dispatch(getDashboardRequestProject({
                        filterStartDate: props.dateStart,
                        filterEndData: props.dateEnd,
                        status: props.status || 0
                    }))
                } else {
                    dispatch(getDashboardProject({
                        filterStartDate: props.dateStart,
                        filterEndData: props.dateEnd,
                        status: props.status || 0,
                        area: filterArea,
                        customer: filterCustomer,
                        assignedFielderId: fielderIdSelected
                    }))
                }
            })
    }

    const onFilterAreaChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setFilterArea(e.target.value)
    }

    const onFilterCustomerChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setFilterCustomer(e.target.value)
    }

    const onFilterSubmit = () => {
        dispatch(getDashboardProject({
            filterStartDate: props.dateStart,
            filterEndData: props.dateEnd,
            status: props.status || 0,
            area: filterArea === "" ? undefined : filterArea,
            customer: filterCustomer === "" ? undefined : filterCustomer,
            assignedFielderId: fielderIdSelected === "" ? undefined : fielderIdSelected
        }))
    }

    const onFilterReset = () => {
        setFilterArea("")
        setFilterCustomer("")
        setFielderIdSelected(undefined)
        setCustomerIdSelected(undefined)
        dispatch(getDashboardProject({
            filterStartDate: props.dateStart,
            filterEndData: props.dateEnd,
            status: props.status || 0,
            area: undefined,
            customer: undefined,
            assignedFielderId: undefined
        }))
    }

    const handleExportCsv = () => {
        const csvData = projectList.map((item) => ({
            "Project": item.projectNumber,
            "Created By": `${item.createdByName} at ${item.createdDateString}`,
            "Project Customer Code": item.customerCodeString,
            "Qty": `${item.fieldingRequestCount} Request`,
            "Location": item.fpAddress,
            "Due Date": item.dueDateString,
        }));
        const csvString = Papa.unparse(csvData, {header: true});
        const blob = new Blob([csvString], {type: 'text/csv;charset=utf-8;'});
        const url = URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        link.download = `Activity Status by Due Date ${moment(props.dateStart).format('YYYY-MM-DD')} - ${moment(props.dateEnd).format('YYYY-MM-DD')}.csv`;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    }

    return (
        <Box>
            <Box sx={{display: "flex", gap: 2, my: 2, flexWrap: "wrap"}}>
                <TextField
                    label='Filter Area'
                    sx={{width: 200}}
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position='start'>
                                <Search/>
                            </InputAdornment>
                        ),
                        endAdornment:
                            filterArea !== "" ? (
                                <InputAdornment position="end">
                                    <IconButton
                                        onClick={() => {
                                            setFilterArea("")
                                        }}
                                    >
                                        <Close />
                                    </IconButton>
                                </InputAdornment>
                            ) : undefined
                    }}
                    value={filterArea}
                    onChange={onFilterAreaChange}
                />

                <Autocomplete
                    sx={{minWidth: 200}}
                    options={dataCustomerCodeList}
                    getOptionLabel={(code) => code.value}
                    value={dataCustomerCodeList.filter(code => code.id === customerIdSelected)[0] || null}
                    onChange={(e, value) => {
                        setCustomerIdSelected(value?.id)
                        setFilterCustomer(value?.value)
                    }}
                    renderInput={(params) => <TextField {...params} label="Project customer code"/>}
                />
                {/*<TextField*/}
                {/*    label='Filter Customer'*/}
                {/*    sx={{width: 200}}*/}
                {/*    InputProps={{*/}
                {/*        startAdornment: (*/}
                {/*            <InputAdornment position='start'>*/}
                {/*                <Search/>*/}
                {/*            </InputAdornment>*/}
                {/*        ),*/}
                {/*        endAdornment:*/}
                {/*            filterArea !== "" ? (*/}
                {/*                <InputAdornment position="end">*/}
                {/*                    <IconButton*/}
                {/*                        onClick={() => {*/}
                {/*                            setFilterCustomer("")*/}
                {/*                        }}*/}
                {/*                    >*/}
                {/*                        <Close />*/}
                {/*                    </IconButton>*/}
                {/*                </InputAdornment>*/}
                {/*            ) : undefined*/}
                {/*    }}*/}
                {/*    value={filterCustomer}*/}
                {/*    onChange={onFilterCustomerChange}*/}
                {/*/>*/}
                <Autocomplete
                    sx={{width: 200}}
                    options={dataFielderList}
                    getOptionLabel={(value: { id: string, fullName: string }) => formatName(value.fullName)}
                    onChange={(e, value) => setFielderIdSelected(value?.id || '')}
                    value={dataFielderList.find((data) => data.id === fielderIdSelected)}
                    renderInput={(params) => <TextField {...params} label="Filter Fielder"/>}
                />
                <Button variant="contained" onClick={onFilterSubmit}>Submit</Button>
                <Button variant="contained" color={"secondary"} onClick={onFilterReset}>Reset</Button>
                <Button variant="contained" startIcon={<Description/>} onClick={handleExportCsv}>
                    Export to CSV
                </Button>
            </Box>
            {projectList.map((project, projectIndex) => {
                return (
                    <Box
                        borderRadius={1}
                        bgcolor={theme.palette.background.paper}
                        key={project.id}
                        sx={{
                            marginTop: projectIndex === 0 ? 0 : 2,
                            marginBottom: projectIndex === projectList.length - 1 ? 8 : 2
                        }}
                    >
                        <Box
                            padding={2}
                        >
                            <Grid container spacing={2} alignItems={"flex-start"}>
                                <Grid item md={2}>
                                    <Typography variant="body1" fontWeight={"bold"} marginLeft={1}>Project</Typography>
                                    <Button
                                        onClick={() => navigate(`/project/detail?id=${project.id}`)}
                                        style={{
                                            display: "flex",
                                            justifyContent: "flex-start",
                                            width: "-webkit-fill-available"
                                        }}
                                        color={project.isHotJob ? "error" : "primary"}
                                    >
                                        <Box display={"flex"} alignItems={"center"} gap={1}>
                                            <span style={{textAlign: "left"}}>
                                                {project.projectNumber}
                                            </span>
                                            {project.isHotJob && (
                                                <img src={hot} alt="hot" style={{width: "20px"}}/>
                                            )}
                                        </Box>
                                    </Button>
                                    <Typography variant="caption" fontStyle={"italic"} color={"grey"} marginLeft={1}>Created
                                        by {project.createdByName} at {project.createdDateString}</Typography>
                                </Grid>
                                <Grid item md={3}>
                                    <Typography variant="body1" fontWeight={"bold"}>Project Customer Code</Typography>
                                    <Typography variant="body1">{project.customerCodeString}</Typography>
                                </Grid>
                                <Grid item md={3}>
                                    <Typography variant="body1" fontWeight={"bold"}>Location</Typography>
                                    <Typography variant="body1">{project.fpAddress}</Typography>
                                </Grid>
                                <Grid item md={4} lg={1}>
                                    <Typography variant="body1" fontWeight={"bold"}>Due Date</Typography>
                                    <Typography variant="body1">{project.dueDateString}</Typography>
                                </Grid>
                                <Grid item md={12} lg={3} display={"flex"} flexGrow={1} alignItems={"flex-end"}
                                      justifyContent={"flex-end"}>
                                    <Button
                                        color="error"
                                        startIcon={<Delete/>}
                                        onClick={() => handleDelete(project.id)}
                                    >
                                        Delete
                                    </Button>
                                    <Button
                                        color="success"
                                        startIcon={<Edit/>}
                                        onClick={() => navigate(`/project/detail?id=${project.id}`)}
                                    >
                                        Edit
                                    </Button>
                                    {
                                        project.fpProjectStatus === 0 && (
                                            <Button
                                                color="warning"
                                                startIcon={<Check/>}
                                                onClick={() => handleComplete(project.id)}
                                            >
                                                Complete Project
                                            </Button>
                                        )
                                    }
                                </Grid>
                            </Grid>
                        </Box>
                        <Divider/>
                        <Box
                            padding={2}
                            display={"flex"}
                            justifyContent={"space-between"}
                        >
                            <Box>
                                <Typography variant="body1" fontWeight={"light"} display={"inline"}>qty </Typography>
                                <Typography variant="body1" fontWeight={"bold"}
                                            display={"inline"}>{project.fieldingRequestCount} Request</Typography>
                            </Box>
                            <Box>
                                <Button
                                    startIcon={project.projectFielderIds.length === 0 ?
                                        <PersonRemove color="error"/> : undefined}
                                    endIcon={<East/>}
                                    onClick={() => props.onSelect(project.id)}
                                >
                                    View Request Detail
                                </Button>
                            </Box>
                        </Box>
                    </Box>
                )
            })}
        </Box>
    )
}