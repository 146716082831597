import {Close} from "@mui/icons-material";
import {Card, CardContent, CardHeader, IconButton, Modal} from "@mui/material";

export default function CustomModal(props: {
    open: boolean,
    title: string,
    onClose: any,
    component: any,
    size?: "sm" | "md" | "lg" | "xl",
    styles?: any,
    fullHeight?: boolean,
    hideCloseButton?: boolean,
    zIndex?: number
}) {

    return (
        <Modal
            open={props.open}
            sx={{
                backgroundColor: 'rgba(0, 0, 0, 0.5)',
                zIndex: props?.zIndex ?? 999,
            }}
        >
            <div className="custom-modal">
                <Card className={`custom-modal-content ${props.size}`} style={props.styles ? props.styles : {}}>
                    <CardHeader
                        title={
                            <span>
                                {props.title}
                            </span>
                        }
                        action={
                            props.hideCloseButton ? <></> :
                                <IconButton color="inherit" onClick={() => props.onClose(false)}>
                                    <Close/>
                                </IconButton>
                        }
                    />
                    <CardContent className={`${props.fullHeight ? "fullHeight" : ""}`}>
                        {props.component}
                    </CardContent>
                </Card>
            </div>
        </Modal>
    )
}