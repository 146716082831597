import {
    Autocomplete,
    Box,
    Button,
    Card,
    CardActions,
    CardContent,
    CardHeader,
    Grid,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TextField,
    Typography
} from "@mui/material";
import {useDispatch, useSelector} from "react-redux";
import {RootState} from "../../../store";
import {useEffect, useState} from "react";
import moment from "moment";
import {getDashboardActivity, getDashboardActivityType} from "../dashboard.api";
import CustomDatePicker from "../../../shared/customDatePicker/CustomDatePicker";
import {formatName} from "../../../shared/utils";
import {Description} from "@mui/icons-material";
import Papa from "papaparse"
import {getAccountFielder} from "../../account/account.api";

export default function DashboardActivity() {

    const dispatch = useDispatch<any>()

    const {dataActivityLogs, activityTypeList} = useSelector((state: RootState) => state.dashboard)
    const {dataFielderList} = useSelector((state: RootState) => state.account)

    const [dateStart, setDateStart] = useState(moment().add(-7, 'days').format('YYYY-MM-DD'))
    const [dateEnd, setDateEnd] = useState(moment().format('YYYY-MM-DD'))
    const [fielderIdSelected, setFielderIdSelected] = useState<string | undefined>(undefined)
    const [typeSelected, setTypeSelected] = useState<number | undefined>(undefined)

    useEffect(() => {
        dispatch(getDashboardActivityType())
        dispatch(getAccountFielder())
    }, [dispatch])

    useEffect(() => {
        dispatch(getDashboardActivity({
            filterStartDate: dateStart,
            filterEndDate: dateEnd,
            activityType: typeSelected,
            fielderId: fielderIdSelected || ""
        }))
    }, [dispatch, dateStart, dateEnd, fielderIdSelected, typeSelected])

    const handleExportCsv = () => {
        const csvData = dataActivityLogs.map((item) => ({
            "Project": item.project,
            "Request": item.fieldingRequest,
            "Assigned Fielder": item.name,
            "Last Update": item.activityDateString,
            "Last Action": item.activityTypeString,
            "Mileage": item.miles,
            "Hours": item.durationString
        }));
        const csvString = Papa.unparse(csvData, {header: true});
        const blob = new Blob([csvString], {type: 'text/csv;charset=utf-8;'});
        const url = URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        link.download = `activity logs ${moment(dateStart).format('YYYY-MM-DD')} - ${moment(dateEnd).format('YYYY-MM-DD')}.csv`;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    }

    const dataSorted = [...dataActivityLogs].sort((a, b) => moment(b.activityDateString).diff(moment(a.activityDateString)))

    return (
        <Card variant="outlined">
            <CardHeader
                sx={{minHeight: 88}}
                title="Activity Log"
                action={(
                    <Box display={"flex"} flexWrap={"wrap"} justifyContent={"flex-end"} gap={1}>
                        <CustomDatePicker dateStart={dateStart} dateEnd={dateEnd} setDateStart={setDateStart}
                                          setDateEnd={setDateEnd}/>
                        <Autocomplete
                            sx={{width: 200}}
                            options={dataFielderList}
                            getOptionLabel={(value: { id: string, fullName: string }) => formatName(value.fullName)}
                            onChange={(e, value) => setFielderIdSelected(value?.id || '')}
                            value={dataFielderList.find((data) => data.id === fielderIdSelected)}
                            renderInput={(params) => <TextField {...params} label="Filter Fielder"/>}
                        />
                        <Autocomplete
                            sx={{width: 200}}
                            options={activityTypeList}
                            getOptionLabel={(option) => option.display}
                            onChange={(e, value) => setTypeSelected(value?.value)}
                            renderInput={(params) => <TextField {...params} label="Filter Action"/>}
                        />
                    </Box>
                )}
            />
            <CardContent>
                <TableContainer sx={{height: 675}}>
                    <Table stickyHeader>
                        <TableHead>
                            <TableRow>
                                <TableCell>Project</TableCell>
                                <TableCell>Request</TableCell>
                                <TableCell>Assigned Fielder</TableCell>
                                <TableCell>Last Update</TableCell>
                                <TableCell>Last Action</TableCell>
                                <TableCell>Mileage</TableCell>
                                <TableCell>Hours</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {dataSorted.map((item, index) => (
                                <TableRow key={index}>
                                    <TableCell>{item.project}</TableCell>
                                    {/* item.fieldingRequest take the last split with " " */}
                                    <TableCell sx={{whiteSpace: "nowrap"}}>{
                                        item.fieldingRequest?.split(" ")?.pop()
                                    }</TableCell>
                                    <TableCell>{formatName(item.name)}</TableCell>
                                    <TableCell>{item.activityDateString}</TableCell>
                                    <TableCell>{item.activityTypeString}</TableCell>
                                    <TableCell>{item.miles}</TableCell>
                                    <TableCell>{item.durationString}</TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </CardContent>
            <CardActions>
                <Box marginTop={2}>
                    <Button startIcon={<Description/>} onClick={handleExportCsv}>
                        Export to CSV
                    </Button>
                </Box>
            </CardActions>
        </Card>
    )
}