import {Box, CircularProgress, Typography} from "@mui/material";
import {useState} from "react";
import {supportedImageTypes} from "../../../state";
import {FileIcon, defaultStyles} from 'react-file-icon';

// @ts-ignore
import {TIFFViewer} from 'react-tiff'
import 'react-tiff/dist/index.css'

export default function ExpenseDetailAttachmentItem(props: { name: string, path: string, onClick?: () => void }) {

    const [isLoading, setIsLoading] = useState(supportedImageTypes.includes(props.name.split('.').pop() as string) && props.name.split('.').pop() !== "tiff")

    // @ts-ignore
    return (
        <Box
            position={"relative"}
            width={150}
            height={150}
            sx={{cursor: 'pointer'}}
        >
            {isLoading && (
                <Box
                    position={"absolute"}
                    top={"50%"}
                    left={"50%"}
                    sx={{transform: "translate(-50%, -50%)"}}
                >
                    <CircularProgress color="inherit"/>
                </Box>
            )}
            {supportedImageTypes.includes(props.name.split('.').pop()?.toLowerCase() as string) ? (

                <>
                    {
                        props.name.split('.').pop()?.toLowerCase() === "tiff" ? (
                            <div onClick={props.onClick}>
                                <TIFFViewer
                                    tiff={props.path + "?width=150"}
                                    lang='en' // en | de | fr | es | tr | ja | zh | ru | ar | hi
                                    paginate='ltr' // bottom | ltr
                                    buttonColor='#141414'
                                    onLoad={() => setIsLoading(false)}
                                    onError={(err: any) => setIsLoading(false)}
                                />
                            </div>
                        ) : (
                            <img
                                src={props.path + "?width=150"}
                                height={150}
                                alt={props.name}
                                onLoad={() => setIsLoading(false)}
                                onError={(err) => setIsLoading(false)}
                                style={{
                                    width: "100%",
                                    height: "100%",
                                    objectFit: "cover"
                                }}
                                onClick={props.onClick}
                            />
                        )
                    }
                </>

            ) : (
                <Box onLoad={() => setIsLoading(false)} width={'80px'} m={2} onClick={props.onClick}
                     sx={{cursor: 'pointer'}}>
                    <FileIcon
                        extension={props.name.split('.').pop()?.toLowerCase() || ''} {...(defaultStyles[props.name.split('.').pop()?.toLowerCase() as keyof typeof defaultStyles] || {})} />
                    <Typography noWrap>{props.name}</Typography>
                </Box>
            )}
        </Box>
    )
}