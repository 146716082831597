import {
   Card,
   CardContent,
   CardHeader,
   Table,
   TableBody,
   TableCell,
   TableContainer,
   TableHead,
   TableRow,
   Typography,
} from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../store';
import { useEffect, useState } from 'react';
import moment from 'moment';
import { getDashboardFielderNotes } from '../dashboard.api';
import CustomDatePicker from '../../../shared/customDatePicker/CustomDatePicker';
import { formatName } from '../../../shared/utils';

export default function OntimeNotes() {
   const dispatch = useDispatch<any>();

   const { dataNoteList } = useSelector((state: RootState) => state.dashboard);

   const [dateStart, setDateStart] = useState(
      moment().add(-7, 'days').format('YYYY-MM-DD')
   );
   const [dateEnd, setDateEnd] = useState(moment().format('YYYY-MM-DD'));

   useEffect(() => {
      dispatch(
         getDashboardFielderNotes({
            filterStartDate: dateStart,
            filterEndData: dateEnd,
         })
      );
   }, [dispatch, dateStart, dateEnd]);

   return (
      <Card variant="outlined">
         <CardHeader
             sx={{ minHeight: 88 }}
            title={<Typography variant="h5">Notes</Typography>}
            action={
               <CustomDatePicker
                  dateStart={dateStart}
                  dateEnd={dateEnd}
                  setDateStart={setDateStart}
                  setDateEnd={setDateEnd}
               />
            }
         />
         <CardContent>
            <TableContainer sx={{ height: 315 }}>
               <Table stickyHeader>
                  <TableHead>
                     <TableRow>
                        <TableCell>Project</TableCell>
                        <TableCell>Fielding Request</TableCell>
                        <TableCell>Date</TableCell>
                        <TableCell>From</TableCell>
                        <TableCell>To</TableCell>
                        <TableCell>Notes</TableCell>
                     </TableRow>
                  </TableHead>
                  <TableBody>
                     {dataNoteList.map((item, index) => (
                        <TableRow key={index}>
                           <TableCell>{item.project}</TableCell>
                           <TableCell>{item.fieldingRequest}</TableCell>
                           <TableCell>{item.date}</TableCell>
                           <TableCell>{formatName(item.from)}</TableCell>
                           <TableCell>{formatName(item.to)}</TableCell>
                           <TableCell>{item.notes}</TableCell>
                        </TableRow>
                     ))}
                  </TableBody>
               </Table>
            </TableContainer>
         </CardContent>
      </Card>
   );
}
