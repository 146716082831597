import {Box, Card, CardContent, CardHeader, Link, Typography, useMediaQuery, useTheme} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../store";
import { useEffect, useState } from "react";
import moment from "moment";
import { getDashboardFieldingDate } from "../dashboard.api";
import CustomDatePicker from "../../../shared/customDatePicker/CustomDatePicker";
import CustomModal from "../../../shared/customModal/CustomModal";
import DashboardProject from "../../dashbord/component/DashboardProject";

export default function OntimeRequestActivityStatus(props: {onSelect: (projectId: string) => void }) {

    const dispatch = useDispatch<any>()
    const theme = useTheme()
    const isMobile = useMediaQuery("(max-width:768px)")

    const { dataFieldingDate } = useSelector((state: RootState) => state.dashboard)

    const [dateStart, setDateStart] = useState(moment().add(-30, 'days').format('YYYY-MM-DD'))
    const [dateEnd, setDateEnd] = useState(moment().format('YYYY-MM-DD'))
    const [statusSelected, setStatusSelected] = useState<undefined | number>()

    useEffect(() => {
        dispatch(getDashboardFieldingDate({ filterStartDate: dateStart, filterEndData: dateEnd }))
    }, [dispatch, dateStart, dateEnd])

    return (
        <Card variant="outlined" sx={{ display: "flex", flexDirection: "column", height: "100%" }}>
            <CustomModal
                open={statusSelected !== undefined}
                onClose={() => setStatusSelected(undefined)}
                title='Project List'
                size="xl"
                fullHeight
                styles={{
                    backgroundColor: '#f0f0f0'
                }}
                component={
                    <DashboardProject dateStart={dateStart} dateEnd={dateEnd} status={statusSelected} onSelect={props.onSelect} isTotalFieldingRequest={false}/>
                }
            />
            <CardHeader title="Activity Status by Due Date" className="custom-action" action={<CustomDatePicker dateStart={dateStart} dateEnd={dateEnd} setDateStart={setDateStart} setDateEnd={setDateEnd} />} />
            <CardContent sx={{ display: "flex", flexDirection: "column", justifyContent: "flex-end", height: "100%" }}>
                <Box display={"flex"} alignItems={"flex-end"} gap={1} height={"100%"}>
                    <Box
                        width={"100%"}
                        height={"100%"}
                        minHeight={200}
                        display={"flex"}
                        flexDirection={"column"}
                        justifyContent={"flex-end"}
                        component={"button"}
                        sx={{  bgcolor: "transparent", border: "none", ":hover": { backgroundColor: '#E5E5E5', transition: '0.5s' } }}

                    >
                        <Box sx={{cursor: 'pointer'}} onClick={() => setStatusSelected(1)} bgcolor={theme.palette.error.main} width={"100%"} height={`${dataFieldingDate.unassignedOverduePercentage}%`}
                        ></Box>
                        <Box sx={{cursor: 'pointer'}} onClick={() => setStatusSelected(0)} bgcolor={theme.palette.primary.main} width={"100%"} height={`${dataFieldingDate.unassignedPercentage}%`}
                        ></Box>

                        <Box width={"100%"}>
                            <Typography fontWeight={"light"}>Unassigned</Typography>
                            <Link sx={{cursor: 'pointer'}} onClick={() => setStatusSelected(0)}><Typography fontWeight={"bold"}>{dataFieldingDate.unassigned} Unassigned</Typography></Link>
                            <Link sx={{cursor: 'pointer'}} onClick={() => setStatusSelected(1)}><Typography fontWeight={"bold"}>{dataFieldingDate.unassignedOverdue} Unassigned Overdue</Typography></Link>

                            {/*<Typography variant={isMobile ? "h6" : "h4"} color={theme.palette.primary.main}>{dataFieldingDate.unassignedPercentage?.toFixed(2)}</Typography>*/}
                        </Box>
                    </Box>
                    <Box
                        width={"100%"}
                        height={"100%"}
                        minHeight={200}
                        display={"flex"}
                        flexDirection={"column"}
                        justifyContent={"flex-end"}
                        component={"button"}
                        sx={{ bgcolor: "transparent", border: "none", ":hover": { backgroundColor: '#E5E5E5', transition: '0.5s' } }}
                    >
                        <Box sx={{cursor: 'pointer'}} onClick={() => setStatusSelected(3)} bgcolor={theme.palette.error.main} width={"100%"} height={`${dataFieldingDate.assignedOverduePercentage}%`}></Box>
                        <Box sx={{cursor: 'pointer'}} onClick={() => setStatusSelected(2)} bgcolor={theme.palette.primary.main} width={"100%"} height={`${dataFieldingDate.assignedPercentage}%`}></Box>
                        <Box width={"100%"}>
                            <Typography fontWeight={"light"}>Assigned</Typography>
                            <Link sx={{cursor: 'pointer'}} onClick={() => setStatusSelected(2)}><Typography fontWeight={"bold"}>{dataFieldingDate.assigned} Assigned</Typography></Link>
                            <Link sx={{cursor: 'pointer'}} onClick={() => setStatusSelected(3)}><Typography fontWeight={"bold"}>{dataFieldingDate.assignedOverdue} Assigned Overdue</Typography></Link>

                            {/*<Typography variant={isMobile ? "h6" : "h4"} color={theme.palette.primary.main}>{dataFieldingDate.assignedPercentage?.toFixed(2)}</Typography>*/}
                        </Box>
                    </Box>
                    <Box
                        width={"100%"}
                        height={"100%"}
                        minHeight={200}
                        display={"flex"}
                        flexDirection={"column"}
                        justifyContent={"flex-end"}
                        component={"button"}
                        sx={{ bgcolor: "transparent", border: "none", ":hover": { backgroundColor: '#E5E5E5', transition: '0.5s' } }}
                    >
                        <Box sx={{cursor: 'pointer'}} onClick={() => setStatusSelected(4)} bgcolor={theme.palette.warning.main} width={"100%"} height={`${dataFieldingDate.onGoingPercentage}%`}></Box>
                        <Box sx={{cursor: 'pointer'}} onClick={() => setStatusSelected(5)} bgcolor={theme.palette.primary.main} width={"100%"} height={`${dataFieldingDate.completedPercentage}%`}></Box>
                        <Box width={"100%"}>
                            <Typography fontWeight={"light"}>Completed</Typography>
                            <Link sx={{cursor: 'pointer'}} onClick={() => setStatusSelected(4)}><Typography fontWeight={"bold"}>{dataFieldingDate.onGoing} Ongoing</Typography></Link>
                            <Link sx={{cursor: 'pointer'}} onClick={() => setStatusSelected(5)}><Typography fontWeight={"bold"}>{dataFieldingDate.completed} Completed</Typography></Link>

                            {/*<Typography variant={isMobile ? "h6" : "h4"} color={theme.palette.primary.main}>{dataFieldingDate.onGoingPercentage?.toFixed(2)}</Typography>*/}
                        </Box>
                    </Box>
                </Box>
            </CardContent>
        </Card>
    )
}