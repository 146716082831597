import { Box, Container, Grid } from "@mui/material";
import DashboardHeader from "./DashboardHeader";
import DashbordTotalFieldingRequest from "./DashboardTotalFieldingRequest";
import DashboardRequestActivityStatus from "./DashboardRequestActivityStatus";
import DashboardActiveFielder from "./DashboardActiveFielder";
import DashboardActivity from "./DashboardActivity";
import DashboardCalendar from "./DashboardCalendar";
import DashboardNotes from "./DashboardNotes";
import ProjectFieldingDetail from "../../project/component/ProjectFieldingDetail";
import { useState } from "react";
import DashboardManager from "./DashboardManager";
import DashboardExpenses from "./DashboardExpenses";

export default function Dashboard() {

    const [projectIdSelected, setProjectIdSelected] = useState<string | undefined>(undefined)

    return (
        <Container maxWidth={"xl"}>
            <ProjectFieldingDetail projectId={projectIdSelected} onClose={() => setProjectIdSelected(undefined)} />
            <br />
            <Grid container spacing={2} alignItems={"stretch"}>
                <Grid item xs={12}>
                    <DashboardHeader />
                </Grid>
                <Grid item xs={12} md={6} lg={4}>
                    <DashbordTotalFieldingRequest onSelect={setProjectIdSelected} />
                </Grid>
                {/*<Grid item xs={12} md={6} lg={4}>*/}
                    {/*<DashboardRequestActivityStatus onSelect={setProjectIdSelected} />*/}
                {/*</Grid>*/}
                {/*<Grid item xs={12} lg={4}>*/}
                    {/*<DashboardCalendar />*/}
                {/*</Grid>*/}
                {/*<Grid item xs={12} lg={4}>*/}
                {/*    <Box display={"flex"} flexDirection={"column"} gap={2}>*/}
                {/*        <DashboardActiveFielder />*/}
                {/*        <DashboardNotes />*/}
                {/*    </Box>*/}
                {/*</Grid>*/}
                <Grid item xs={12} lg={12}>
                    <DashboardActivity />
                </Grid>
                <Grid item xs={12} lg={12}>
                    <DashboardManager />
                </Grid>
                {/*<Grid item xs={12} lg={12}>*/}
                {/*    <DashboardExpenses />*/}
                {/*</Grid>*/}
            </Grid>
            <br />
        </Container>
    )
}