import { createSlice } from "@reduxjs/toolkit";
import {
    InitialExpenseGet,
    InitialMileageGet,
    InterfaceExpense,
    InterfaceExpenseDetail,
    InterfaceExpenseGet, InterfaceMileage, InterfaceMileageGet
} from "./expense.interface";
import {
    approveExpense,
    createExpense, deleteExpense,
    generateDocNumber,
    getExpense,
    getExpenseDetail,
    getMileage, getPersonalExpense,
    rejectExpense, updateExpense
} from "./expense.api";

export interface ExpenseState {
    loading: boolean;
    parameterGetData: InterfaceExpenseGet;
    parameterGetDataMileage: InterfaceMileageGet;
    dataList: InterfaceExpense[];
    dataMileageList: InterfaceMileage[];
    dataTotal: number;
    dataDetail: InterfaceExpenseDetail | null;
    newDocNumber: string;
}

export const initialState: ExpenseState = {
    loading: false,
    parameterGetData: InitialExpenseGet,
    parameterGetDataMileage: InitialMileageGet,
    dataList: [],
    dataMileageList: [],
    dataTotal: 0,
    dataDetail: null,
    newDocNumber: ''
};

export const expenseSlice = createSlice({
    name: 'expense',
    initialState,
    reducers: {
        setParameterGetData: (state, action) => {
            state.parameterGetData = action.payload;
        },
        resetDataDetail: (state) => {
            state.dataDetail = null;
        }
    },
    extraReducers: (builder) => {

        builder.addCase(createExpense.pending, (state, action) => {
            state.loading = true;
        });
        builder.addCase(createExpense.fulfilled, (state, action) => {
            state.loading = false;
        });
        builder.addCase(createExpense.rejected, (state, action) => {
            state.loading = false;
        });

        builder.addCase(updateExpense.pending, (state, action) => {
            state.loading = true;
        });
        builder.addCase(updateExpense.fulfilled, (state, action) => {
            state.loading = false;
        });
        builder.addCase(updateExpense.rejected, (state, action) => {
            state.loading = false;
        });

        builder.addCase(deleteExpense.pending, (state, action) => {
            state.loading = true;
        });
        builder.addCase(deleteExpense.fulfilled, (state, action) => {
            state.loading = false;
        });
        builder.addCase(deleteExpense.rejected, (state, action) => {
            state.loading = false;
        });

        builder.addCase(getExpense.pending, (state, action) => {
            state.loading = true;
        });
        builder.addCase(getExpense.fulfilled, (state, action) => {
            state.loading = false;
            state.dataList = action.payload.data.data.data;
            state.dataTotal = action.payload.data.data.total;
        });
        builder.addCase(getExpense.rejected, (state, action) => {
            state.loading = false;
        });

        builder.addCase(getPersonalExpense.pending, (state, action) => {
            state.loading = true;
        });
        builder.addCase(getPersonalExpense.fulfilled, (state, action) => {
            state.loading = false;
            state.dataList = action.payload.data.data.data;
            state.dataTotal = action.payload.data.data.total;
        });
        builder.addCase(getPersonalExpense.rejected, (state, action) => {
            state.loading = false;
        });

        builder.addCase(getMileage.pending, (state, action) => {
            state.loading = true;
        });
        builder.addCase(getMileage.fulfilled, (state, action) => {
            state.loading = false;
            state.dataMileageList = action.payload.data.data;
        });
        builder.addCase(getMileage.rejected, (state, action) => {
            state.loading = false;
        });

        builder.addCase(approveExpense.pending, (state, action) => {
            state.loading = true;
        });
        builder.addCase(approveExpense.fulfilled, (state, action) => {
            state.loading = false;
        });
        builder.addCase(approveExpense.rejected, (state, action) => {
            state.loading = false;
        });

        builder.addCase(rejectExpense.pending, (state, action) => {
            state.loading = true;
        });
        builder.addCase(rejectExpense.fulfilled, (state, action) => {
            state.loading = false;
        });
        builder.addCase(rejectExpense.rejected, (state, action) => {
            state.loading = false;
        });

        builder.addCase(getExpenseDetail.pending, (state, action) => {
            state.loading = true;
        });
        builder.addCase(getExpenseDetail.fulfilled, (state, action) => {
            state.loading = false;
            state.dataDetail = action.payload.data.data;
        });
        builder.addCase(getExpenseDetail.rejected, (state, action) => {
            state.loading = false;
        });
        //generateDocNumber
        builder.addCase(generateDocNumber.pending, (state, action) => {
            state.loading = true;
        });
        builder.addCase(generateDocNumber.fulfilled, (state, action) => {
            state.loading = false;
            state.newDocNumber = action.payload.data.data;
        });
        builder.addCase(generateDocNumber.rejected, (state, action) => {
            state.loading = false;
        });
    },
});

export const { setParameterGetData, resetDataDetail } = expenseSlice.actions;

export default expenseSlice.reducer;