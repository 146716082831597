import React, {useEffect} from 'react';
import {Box, Table, TableBody, TableCell, TableHead, TableRow} from "@mui/material";
import {useDispatch, useSelector} from "react-redux";
import {RootState} from "../../../store";
import { getDashboardActiveFielderDetails} from "../dashboard.api";

const OntimeActiveFielderDetails = (props: {
    dateStart: string,
    dateEnd: string,
    targetFielderId: string | undefined,
}) => {

    const dispatch = useDispatch<any>()

    const {dateStart, dateEnd, targetFielderId} = props;

    const {activeFielderDetails} = useSelector((state: RootState) => state.dashboard)

    useEffect(() => {
        if (targetFielderId !== undefined) {
            dispatch(getDashboardActiveFielderDetails({ filterStartDate: dateStart, filterEndData: dateEnd, targetFielderId: targetFielderId }))
        }
    }, [dispatch, dateStart, dateEnd, targetFielderId])

    return (
        <Box>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell>Project</TableCell>
                        <TableCell>Fielding Request</TableCell>
                        <TableCell>Total Request</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {activeFielderDetails?.map((item, index) => (
                        <TableRow key={index}>
                            <TableCell>{item.project ?? "-"}</TableCell>
                            <TableCell>{item.fieldingRequest}</TableCell>
                            <TableCell>{item.totalRequest}</TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </Box>
    );
};

export default OntimeActiveFielderDetails;