import { createAsyncThunk } from "@reduxjs/toolkit"
import {
    InterfaceExpenseCreate,
    InterfaceExpenseGet,
    InterfaceExpenseUpdate,
    InterfaceMileageGet
} from "./expense.interface"
import axios from "axios"
import { baseUrl } from "../../state"
import handleError from "../../shared/handleError"
import { userInfo } from "../../shared/utils"

export const createExpense = createAsyncThunk("createExpense", async (data: InterfaceExpenseCreate) => {
    try {
        const response = await axios({
            method: "post",
            url: baseUrl + "/newapi/Billing/Create",
            data: {
                ...data,
                token: userInfo().token
            }
        })
        return response
    } catch (error: any) {
        handleError(error)
        return error.response
    }
})

export const updateExpense = createAsyncThunk("updateExpense", async (data: InterfaceExpenseUpdate) => {
    try {
        const response = await axios({
            method: "post",
            url: baseUrl + "/newapi/Billing/Edit",
            data: {
                ...data,
                token: userInfo().token
            }
        })
        return response
    } catch (error: any) {
        handleError(error)
        return error.response
    }
})

export const getExpense = createAsyncThunk("getExpense", async (data: InterfaceExpenseGet) => {
    try {
        const response = await axios({
            method: "post",
            url: baseUrl + "/newapi/Billing/GetAllBillingHistory",
            data: {
                ...data,
                token: userInfo().token
            }
        })
        return response
    } catch (error: any) {
        handleError(error)
        return error.response
    }
})

export const getPersonalExpense = createAsyncThunk("getPersonalExpense", async (data: InterfaceExpenseGet) => {
    try {
        const response = await axios({
            method: "post",
            url: baseUrl + "/newapi/Billing/GetPersonalBillingHistory",
            data: {
                ...data,
                token: userInfo().token
            }
        })
        return response
    } catch (error: any) {
        handleError(error)
        return error.response
    }
})


export const deleteExpense = createAsyncThunk("deleteExpense", async (id: string) => {
    try {
        const response = await axios({
            method: "delete",
            url: baseUrl + "/newapi/Billing/Delete",
            params: {
                targetId: id,
                token: userInfo().token,
            }
        })
        return response
    } catch (error: any) {
        handleError(error)
        return error.response
    }
})

export const approveExpense = createAsyncThunk("approveExpense", async (id: string) => {
    try {
        const response = await axios({
            method: "post",
            url: baseUrl + "/newapi/Billing/SetApproval",
            data: {
                id,
                token: userInfo().token,
                isApproved: true
            }
        })
        return response
    } catch (error: any) {
        handleError(error)
        return error.response
    }
})

export const rejectExpense = createAsyncThunk("rejectExpense", async (data: { id: string, reason: string }) => {
    try {
        const response = await axios({
            method: "post",
            url: baseUrl + "/newapi/Billing/SetApproval",
            data: {
                id: data.id,
                approvalMessage: data.reason,
                token: userInfo().token,
                isApproved: false
            }
        })
        return response
    } catch (error: any) {
        handleError(error)
        return error.response
    }
})

export const getExpenseDetail = createAsyncThunk("getExpenseDetail", async (id: string) => {
    try {
        const response = await axios({
            method: "get",
            url: baseUrl + "/newapi/Billing/GetDetails",
            params: {
                id,
                token: userInfo().token
            }
        })
        return response
    } catch (error: any) {
        handleError(error)
        return error.response
    }
})

export const getMileage = createAsyncThunk("getMileage", async (data: InterfaceMileageGet) => {
    try {
        const response = await axios({
            method: "post",
            url: baseUrl + "/api/Report/FPGetMileageReport",
            data: {
                ...data,
                token: userInfo().token
            }
        })
        return response
    } catch (error: any) {
        handleError(error)
        return error.response
    }
})

export const generateDocNumber = createAsyncThunk("generateDocNumber", async () => {
    try {
        const response = await axios({
            method: "get",
            url: baseUrl + "/newapi/Billing/GenerateNewDocNumber",
            params: {
                token: userInfo().token
            }
        })
        return response
    } catch (error: any) {
        handleError(error)
        return error.response
    }
})