import {BusinessCenter, DashboardCustomize, Engineering, PersonAdd, RequestQuote, Timelapse } from "@mui/icons-material"

import Account from "./pages/account/component/Account"
import Credit from "./pages/credit/component/Credit"
import Fielding from "./pages/fielding/component/Fielding"
import FieldingDetail from "./pages/fielding/component/FieldingDetail"
import Project from "./pages/project/component/Project"
import ProjectAssign from "./pages/project/component/ProjectAssign"
import ProjectForm from "./pages/project/component/ProjectForm"
import ProjectFormFielding from "./pages/project/component/ProjectFormFIelding"
import ProjectMapDetail from "./pages/project/component/ProjectMapDetail"
import {InterfaceAccess} from "./shared/roles.interface"
import Expense from "./pages/expense/component/Expense"
import Dashboard from "./pages/dashbord/component/Dashbord"
import Calendar from "./pages/calendar/components/Calendar"
import ExpensesPage from "./pages/expense/component/ExpensesPage";
import Ontime from "./pages/ontime/component/Ontime";

const routeList: Array<{
    path: string,
    component: any,
    name?: string,
    icon?: any,
    access: InterfaceAccess
}> = [
    {
        path: "/dashboard",
        component: <Dashboard/>,
        access: "PROJECT",
        name: "Dashboard",
        icon: <DashboardCustomize/>
    },
    {
        path: "/calendar",
        component: <Calendar/>,
        access: "PROJECT"
    },
    {
        path: "/project",
        component: <Project/>,
        access: "PROJECT",
        name: "Project",
        icon: <BusinessCenter/>
    },
    {
        path: "/expense",
        component: <ExpensesPage/>,
        access: "PAYMENT",
        name: "Expenses",
        icon: <RequestQuote/>
    },
    {
        path: "/project/create",
        component: <ProjectForm type='create'/>,
        access: "PROJECT"
    },
    {
        path: "/project/detail",
        component: <ProjectForm type='detail'/>,
        access: "PROJECT"
    },
    {
        path: "/project/detail/fieldrequest",
        component: <ProjectFormFielding/>,
        access: "PROJECT"
    },
    {
        path: "/project/detailmap",
        component: <ProjectMapDetail/>,
        access: "PROJECT"
    },
    {
        path: "/project/assign",
        component: <ProjectAssign/>,
        access: "PROJECT"
    },
    {
        path: "/account",
        component: <Account/>,
        access: "USER",
        name: "User",
        icon: <PersonAdd/>
    },
    {
        path: "/ontime-status",
        component: <Ontime/>,
        access: "PROJECT",
        name: "Ontime Status",
        icon: <Timelapse/>
    },
    {
        path: "/credit",
        component: <Credit/>,
        access: "PAYMENT"
    },
    {
        path: "/fielding",
        component: <Fielding/>,
        access: "FIELDING",
        name: "Go Fielding",
        icon: <Engineering/>
    },
    {
        path: "/fielding/detail",
        component: <FieldingDetail/>,
        access: "FIELDING"
    }
]

export default routeList 