import { Card } from "@mui/material";
import moment from "moment";
import { useEffect, useState } from "react";
import useFieldingClock from "../../../hooks/useFieldingClock";

export default function FieldingClock() {

    const {clock, timeZone} = useFieldingClock()

    // const [clock, setClock] = useState("")
    // const [timeZone, setTimeZone] = useState("")

    // useEffect(() => {
    //     let i = setInterval(() => {
    //         setClock(`${moment().format("MM/DD/YYYY hh:mm:ss")}`)
    //         setTimeZone(`${new Date()
    //             .toLocaleDateString('en-us', { day: "2-digit", timeZoneName: "long" })
    //             .slice(4)
    //             .replace(new RegExp('\\b( Daylight| Standard)\\b', 'gi'), ' ')
    //             .replace(/\s{2,}/g, ' ')
    //             }`)
    //     }, 1000)
    //     return () => {
    //         clearInterval(i)
    //     }
    // }, [])

    return (
        <Card variant="outlined" style={{ padding: "4px 10px" }}>
            <span style={{ fontSize: "14px" }}>
                {clock}
            </span>
            <span style={{margin:"0 5px"}}>{' - '}</span>
            <span style={{ whiteSpace: "nowrap", fontSize: "14px" }}>
                {timeZone}
            </span>
        </Card>
    )
}