import {useEffect, useState} from 'react';
import moment from "moment/moment";

const useFieldingClock = () => {
    const [clock, setClock] = useState("")
    const [clock24, setClock24] = useState("")
    const [timeZone, setTimeZone] = useState("")

    useEffect(() => {
        let i = setInterval(() => {
            setClock(`${moment().format("MM/DD/YYYY hh:mm:ss")}`)
            setClock24(`${moment().format("MM/DD/YYYY HH:mm:ss")}`)
            setTimeZone(`${new Date()
                .toLocaleDateString('en-us', { day: "2-digit", timeZoneName: "long" })
                .slice(4)
                .replace(new RegExp('\\b( Daylight| Standard)\\b', 'gi'), ' ')
                .replace(/\s{2,}/g, ' ')
            }`)
        }, 1000)
        return () => {
            clearInterval(i)
        }
    }, [])

    return {clock, clock24, timeZone} as const
};

export default useFieldingClock;