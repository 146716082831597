import {Box, Button, Divider, IconButton, InputAdornment, TextField, Typography} from "@mui/material";
import { InitialAccountResetPassword, InterfaceAccountData, InterfaceAccountResetPassword } from "../account.interface";
import { useDispatch } from "react-redux";
import {passwordVerification, resetPassword} from "../account.api";
import notificationSuccess from "../../../shared/notificationSuccess";
import notificationError from "../../../shared/notificationError";
import React, { FormEvent, useState } from "react";
import { userInfo } from "../../../shared/utils";
import { Visibility, VisibilityOff } from "@mui/icons-material";

export default function AccountPasswordValidation(props: { onClose: any, onValidate: any }) {

    const dispatch = useDispatch<any>()

    const [values, setValues] = useState<InterfaceAccountResetPassword>(InitialAccountResetPassword)
    const [showPasswordC, setShowPasswordC] = useState(false)

    const handleChange = (e: any) => {
        const { name, value } = e.target
        setValues({
            ...values,
            [name]: value
        })
    }

    const handleSubmit = (e: FormEvent) => {
        e.preventDefault()
        console.log(values)
        dispatch(passwordVerification({
            ...values,
        }))
            .unwrap()
            .then((response: any) => {
                if (response.status === 200) {
                    props.onValidate()
                }
                else {
                    notificationError(response)
                }
            })
    }

    return (
        <Box>
            <form onSubmit={handleSubmit}>
                <Typography>To continue with this action, please confirm your password.</Typography>
                <br />
                <TextField
                    type={showPasswordC ? "text" : "password"}
                    margin="dense"
                    label="Your Password"
                    name="password"
                    value={values.password}
                    onChange={handleChange}
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="end">
                                <IconButton
                                    onClick={() => setShowPasswordC(!showPasswordC)}
                                >
                                    {showPasswordC ? <Visibility /> : <VisibilityOff />}
                                </IconButton>
                            </InputAdornment>
                        )
                    }}
                    required
                />
                <br />
                <br />
                <Button type="submit" fullWidth variant="contained">
                    Submit
                </Button>
            </form>
        </Box>
    )
}