import {
    Box,
    Button,
    Divider,
    Grid,
    IconButton,
    LinearProgress,
    TextField,
    Typography,
    useMediaQuery
} from "@mui/material";
import {useDispatch, useSelector} from "react-redux";
import React, {FormEvent, useEffect, useState} from "react";
import {InitialExpenseCreate, InterfaceExpenseCreate} from "../expense.interface";
import {createExpense, generateDocNumber, updateExpense} from "../expense.api";
import notificationSuccess from "../../../shared/notificationSuccess";
import {Dropzone, setNextUploadStatus} from "@dropzone-ui/react";
import {Cancel, Check, DeleteForever} from "@mui/icons-material";
import Papa from "papaparse";
import {userInfo} from "../../../shared/utils";
import axios from "axios";
import {baseUrl} from "../../../state";
import notificationError from "../../../shared/notificationError";
import {RootState} from "../../../store";
import moment from "moment";
import ExpenseMiniList from "./ExpenseMiniList";
import {resetDataDetail} from "../expense.reducer";

export default function ExpenseCreate(props: { onSubmit: () => void, onHideCloseButton: (hide: boolean) => void }) {

    const dispatch = useDispatch<any>()

    const isMobile = useMediaQuery("(max-width:768px)")

    const {newDocNumber} = useSelector(
        (state: RootState) => state.expense
    );

    const [value, setValue] = useState<InterfaceExpenseCreate>(InitialExpenseCreate)
    const [filesQueue, setFilesQueue] = useState<any>([])
    const [isUploading, setIsUploading] = useState<boolean>(false)

    const [isLoading, setIsLoading] = useState<boolean>(false)

    const [uploadStep, setUploadStep] = useState<boolean>(false)
    const [isCreated, setIsCreated] = useState<boolean>(false)
    const [currentBillingId, setCurrentBillingId] = useState<string | undefined>(undefined)
    const [isEdit, setIsEdit] = useState<boolean>(false)

    const [removeAttachmentIds, setRemoveAttachmentIds] = useState<Array<string>>([])

    const {dataDetail} = useSelector((state: RootState) => state.expense)

    useEffect(() => {
        props.onHideCloseButton(uploadStep)
    }, [uploadStep]);

    const handleSubmit = async (e: FormEvent) => {
        setIsLoading(true)
        e.preventDefault()
        if (isEdit) {
            dispatch(updateExpense({
                id: dataDetail?.id as string,
                amount: value.amount,
                notes: value.notes,
                removedAttachmentIds: removeAttachmentIds,
                processedDate: value.processedDate === "" ? null : value.processedDate
            }))
                .unwrap()
                .then((response: any) => {
                    if (response.status === 200) {
                        setIsCreated(true)
                        setIsLoading(false)
                        setCurrentBillingId(response?.data?.data?.id)
                        setUploadStep(true)
                    } else {
                        setIsCreated(false)
                        setIsLoading(false)
                        setCurrentBillingId(undefined)
                    }
                })
                .catch((error: any) => {
                    console.log(error)
                    setIsCreated(false)
                    setIsLoading(false)
                    setCurrentBillingId(undefined)
                })
        } else {
            dispatch(createExpense({
                ...value,
                docNumber: newDocNumber,
                processedDate: value.processedDate === "" ? null : value.processedDate
            }))
                .unwrap()
                .then((response: any) => {
                    if (response.status === 200) {
                        setIsCreated(true)
                        setIsLoading(false)
                        setCurrentBillingId(response?.data?.data?.id)
                        setUploadStep(true)
                    } else {
                        setIsCreated(false)
                        setIsLoading(false)
                        setCurrentBillingId(undefined)
                    }
                })
                .catch((error: any) => {
                    console.log(error)
                    setIsCreated(false)
                    setIsLoading(false)
                    setCurrentBillingId(undefined)
                })
        }
    }

    useEffect(() => {
        if (!isEdit) {
            dispatch(generateDocNumber())
        }
    }, [isEdit]);

    useEffect(() => {
        if (dataDetail !== null) {
            setValue({
                ...value,
                docNumber: dataDetail.docNumber,
                amount: dataDetail.amount.toString(),
                notes: dataDetail.notes,
                processedDate: dataDetail.processedDate
            })
            setFilesQueue(dataDetail.attachments.map((attachment: any) => {
                return {
                    id: attachment.id,
                    name: attachment.fileName,
                    uploadProgress: 100,
                    file: null
                }
            }))
            setIsEdit(true)
        } else {
            setValue(InitialExpenseCreate)
            setFilesQueue([])
            setIsEdit(false)
        }
    }, [dataDetail]);

    useEffect(() => {
        if (currentBillingId !== undefined && (!isUploading && filesQueue.length > 0 && filesQueue.filter((file: any) => file.uploadProgress < 100).length > 0)) {
            setIsUploading(true)
            uploading(currentBillingId)
        }
    }, [filesQueue, currentBillingId])

    const uploading = async (billingId: string) => {
        let filesLocal: any = filesQueue
        let filesToUpload: any = filesLocal.filter((file: any) => file.uploadProgress === 0)
        if (filesToUpload.length > 0) {
            for (const i in filesToUpload) {
                try {
                    let data = new FormData();
                    data.append("billingId", billingId)
                    data.append("token", userInfo().token)
                    data.append("file", filesToUpload[i].file)
                    await axios.post(`${baseUrl}/newapi/Billing/UploadAttachments`, data, {
                        onUploadProgress: (progressEvent) => {
                            var percentCompleted = Math.round((progressEvent.loaded * 100) / (progressEvent.total || 1))
                            filesLocal = filesLocal.map((f: any) => {
                                if (f.name === filesToUpload[i].name) {
                                    return {
                                        ...f,
                                        uploadProgress: percentCompleted
                                    }
                                }
                                return f
                            })
                            setFilesQueue(filesLocal)
                        }
                    });
                } catch (error: any) {
                    console.log(error)
                    notificationError(error.response)
                }
            }
        }
        setIsUploading(false)
        notificationSuccess("Successfully add expense attachment")
    }

    const onChangeImage = async (filesLocal: any) => {
        if (filesLocal.length > 0) {
            if (filesLocal.length > 0) {
                setFilesQueue([
                    ...filesQueue,
                    ...Array.from(filesLocal).map((file: any) => {
                        return {
                            name: file.name,
                            uploadProgress: 0,
                            file: file.file
                        }
                    })
                ])
            }
        }
    }

    const onFileDelete = (id: string) => () => {
        setFilesQueue(filesQueue.filter((file: any) => file.id !== id))
        setRemoveAttachmentIds([...removeAttachmentIds, id])
    }

    const updateRemoveAttachment = () => {
        dispatch(updateExpense({
            id: dataDetail?.id as string,
            amount: value.amount,
            notes: value.notes,
            removedAttachmentIds: removeAttachmentIds,
            processedDate: value.processedDate === "" ? null : value.processedDate
        }))
            .unwrap()
            .then((response: any) => {
                if (response.status === 200) {
                    setIsCreated(true)
                    setIsLoading(false)
                    setCurrentBillingId(response?.data?.data?.id)
                    setUploadStep(true)
                } else {
                    setIsCreated(false)
                    setIsLoading(false)
                    setCurrentBillingId(undefined)
                }
            })
            .catch((error: any) => {
                console.log(error)
                setIsCreated(false)
                setIsLoading(false)
                setCurrentBillingId(undefined)
            })
    }

    const handleCancel = () => {
        setValue(InitialExpenseCreate)
        setFilesQueue([])
        setIsEdit(false)
        dispatch(resetDataDetail());
    }

    return (

        <>
            {
                uploadStep ? (
                    <Box component={"form"} display={"flex"} flexDirection={"column"} gap={1}>
                        <Box>
                            {/*ony accept image*/}
                            <Dropzone onChange={onChangeImage} header={false} accept="image/*" disabled={isUploading}/>
                            {filesQueue.map((file: any) => (
                                <Box key={file.name}>
                                    <Box sx={{display: "flex", alignItems: "center", justifyContent: "space-between"}}>
                                        {file.name}
                                        <Box>
                                            <IconButton>
                                                {file.uploadProgress < 100
                                                    ? (
                                                        <Cancel/>
                                                    )
                                                    : (
                                                        <Check color="success"/>
                                                    )
                                                }
                                            </IconButton>
                                            {
                                                (isEdit && file.uploadProgress === 100) && (
                                                    <IconButton onClick={onFileDelete(file.id)}>
                                                        <DeleteForever color="error"/>
                                                    </IconButton>
                                                )
                                            }
                                        </Box>
                                    </Box>
                                    <LinearProgress variant="determinate" value={file.uploadProgress}/>
                                </Box>
                            ))}
                        </Box>
                        <Button type="button" variant="contained" fullWidth color="primary"
                                disabled={
                            // check if filesQueue is uploading
                            isUploading || filesQueue.length === 0
                        }
                                onClick={() => {
                                    if (isEdit) updateRemoveAttachment()
                                    props.onSubmit()
                                }}>Done</Button>
                    </Box>
                ) : (
                    <Box sx={{
                        display: {xs: 'block', md: 'flex'},
                    }} gap={2}>
                        {/*  half box */}
                        <Box sx={{
                            width: '100%',
                        }}>
                            <ExpenseMiniList isEdit={isEdit}/>
                        </Box>
                        {!isMobile && <Divider orientation={"vertical"} variant="middle" flexItem sx={{
                            borderWidth: 2,
                            marginY: 0
                        }}/>}
                        <Box sx={{
                            width: {xs: '100%', md: '50%'},
                        }}>
                            <Typography variant="h6" mt={0.5} mb={3}>{isEdit ? 'Edit' : 'Create'} Expense</Typography>
                            <Box component={"form"} display={"flex"} flexDirection={"column"} gap={1}
                                 onSubmit={handleSubmit}>
                                <TextField label="Expense ID" value={isEdit ? value.docNumber ?? "" : newDocNumber}
                                           InputProps={{readOnly: true}}/>
                                <TextField label="Amount" type={'number'} value={parseInt(value.amount || '0')}
                                           onChange={(e) => setValue({
                                               ...value,
                                               amount: String(parseInt(e.target.value || '0'))
                                           })}/>
                                <TextField
                                    fullWidth={false}
                                    value={moment(value.processedDate).format('YYYY-MM-DD')}
                                    onChange={(e) => setValue({
                                        ...value,
                                        processedDate: moment(e.target.value).toISOString()
                                    })}
                                    type="date"
                                    name="dueDateString"
                                    label="Expense Date"
                                    InputLabelProps={{shrink: true}}
                                />
                                <TextField label="Notes" multiline minRows={3} value={value?.notes}
                                           onChange={(e) => setValue({...value, notes: e.target.value})}/>

                                <Box display={isMobile ? 'block' : 'flex'} >
                                    {
                                        (!isCreated && isEdit) && (
                                            <Button type="button" variant="contained" fullWidth={isMobile} color="secondary"
                                                    onClick={handleCancel}
                                                    sx={{
                                                        width: {xs: '100%', md: '50%'},
                                                        mx: {xs: 0, md: 1},
                                                        mb: {xs: 1, md: 0}
                                                    }}
                                                    disabled={isLoading}>Cancel</Button>
                                        )
                                    }
                                    {
                                        isCreated ? (
                                            <Button type="button" variant="contained" fullWidth color="primary"
                                                    onClick={() => setUploadStep(true)}>Next</Button>
                                        ) : (
                                            <Button type="submit" variant="contained" fullWidth={!isEdit || isMobile} color="primary"
                                                    onClick={handleSubmit}
                                                    sx={{
                                                        width: {xs: '100%', md: isEdit ? '50%' : '100%'},
                                                        mx: {xs: 0, md: 1},
                                                        mb: {xs: 1, md: 0}
                                                    }}
                                                    disabled={isLoading}>{isEdit ? 'Done' : 'Create'}</Button>
                                        )
                                    }
                                </Box>
                            </Box>
                        </Box>
                    </Box>
                )
            }
        </>
    )
}