import {Box, Container, Grid} from "@mui/material";
import OntimeHeader from "./OntimeHeader";
import OntimeRequestActivityStatus from "./OntimeRequestActivityStatus";
import OntimeActiveFielder from "./OntimeActiveFielder";
import OntimeCalendar from "./OntimeCalendar";
import OntimeNotes from "./OntimeNotes";
import ProjectFieldingDetail from "../../project/component/ProjectFieldingDetail";
import {useState} from "react";

export default function Ontime() {

    const [projectIdSelected, setProjectIdSelected] = useState<string | undefined>(undefined)

    return (
        <Container maxWidth={"xl"}>
            <ProjectFieldingDetail projectId={projectIdSelected} onClose={() => setProjectIdSelected(undefined)}/>
            <br/>
            <Grid container spacing={2} alignItems={"stretch"}>
                <Grid item xs={12}>
                    <OntimeHeader/>
                </Grid>
                <Grid item xs={12} lg={6}>
                    <OntimeRequestActivityStatus onSelect={setProjectIdSelected}/>
                </Grid>
                <Grid item xs={12} lg={6}>
                    <OntimeCalendar/>
                </Grid>
                <Grid item xs={12} lg={6}>
                    <OntimeActiveFielder/>
                </Grid>
                <Grid item xs={12} lg={6}>
                    <OntimeNotes/>
                </Grid>
            </Grid>
            <br/>
        </Container>
    )
}