import React, {useEffect, useState} from 'react';
import {
    Alert,
    Autocomplete,
    Box,
    Button, CardContent,
    Container, Grid,
    IconButton,
    InputAdornment, Table, TableBody, TableCell, TableContainer, TableHead, TableRow,
    TextField,
    Typography, useMediaQuery
} from "@mui/material";
import {formatName} from "../../../shared/utils";
import {useDispatch, useSelector} from "react-redux";
import {RootState} from "../../../store";
import {getAccountFielder} from "../../account/account.api";
import {CheckCircleOutline, Close, FileDownload, HighlightOffOutlined, Undo} from "@mui/icons-material";
import CustomDatePicker from "../../../shared/customDatePicker/CustomDatePicker";
import moment from "moment/moment";
import {getExpense, getMileage} from "../expense.api";
import {getDashboardManager} from "../../dashbord/dashboard.api";

const Mileage = () => {
    const dispatch = useDispatch<any>()

    const isMobile = useMediaQuery("(max-width:768px)")

    const {dataFielderList} = useSelector((state: RootState) => state.account)
    const {dataMileageList, dataTotal, parameterGetDataMileage} = useSelector((state: RootState) => state.expense)

    // delaying input search
    const [timer, setTimer] = useState(setTimeout(() => {
    }, 1000));

    const [filter, setFilter] = useState<string | undefined>("")
    const [searchText, setSearchText] = useState<string>("")
    const [fielderIdSelected, setFielderIdSelected] = useState<string | undefined>(undefined)
    const [filterRequest, setFilterRequest] = useState<string>("")
    const [dateStart, setDateStart] = useState(moment().add(-7, 'days').format('YYYY-MM-DD'))
    const [dateEnd, setDateEnd] = useState(moment().format('YYYY-MM-DD'))
    const [statusSelected, setStatusSelected] = useState<number | undefined>(undefined)

    useEffect(() => {
        dispatch(getAccountFielder())
    }, [dispatch])

    useEffect(() => {
        dispatch(getMileage(parameterGetDataMileage))
    }, [dispatch, parameterGetDataMileage])

    useEffect(() => {
        dispatch(getMileage({
            filter: filter ?? "",
            filterStartDate: dateStart,
            filterEndDate: dateEnd,
            fielderId: fielderIdSelected ?? "",
            projectStatus: statusSelected
        }))

    }, [dispatch, dateStart, dateEnd, fielderIdSelected, filter, statusSelected])

    const onSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setSearchText(e.target.value);
        clearTimeout(timer);
        setTimer(
            setTimeout(() => {
                setFilter(e.target.value);
            }, 1000)
        );
    }

    return (
        <div className="project">
            <Container maxWidth="xl" sx={{marginTop: 1}}>
                <div
                    style={{
                        display: isMobile ? 'block' : 'flex',
                        flex: 1,
                        flexDirection: 'row',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                        padding: 20,
                        marginTop: 20,
                        marginBottom: 20,
                    }}
                >
                    <Typography variant='h6' mb={isMobile ? 2 : 0}>Mileage</Typography>
                    <Box display={isMobile ? "block" : "flex"} flexWrap={"wrap"} justifyContent={"flex-end"} gap={1}>
                        <Autocomplete
                            sx={{width: isMobile ? '100%' : 200, mb: isMobile ? 1 : 0}}
                            options={dataFielderList}
                            getOptionLabel={(value: { id: string, fullName: string }) => formatName(value.fullName)}
                            onChange={(e, value) => setFielderIdSelected(value?.id || '')}
                            value={dataFielderList.find((data) => data.id === fielderIdSelected)}
                            renderInput={(params) => <TextField {...params}
                                                                sx={{backgroundColor: 'white', borderRadius: '20px'}}
                                                                label="Fielder"/>}
                        />
                        <TextField
                            label='Project'
                            sx={{width: isMobile ? '100%' : 200, mb: isMobile ? 1 : 0, backgroundColor: 'white', borderRadius: '20px'}}
                            InputProps={{
                                // startAdornment: (
                                //     <InputAdornment position='start'>
                                //         <Search/>
                                //     </InputAdornment>
                                // ),
                                endAdornment:
                                    filterRequest !== "" ? (
                                        <InputAdornment position="end">
                                            <IconButton
                                                onClick={() => {
                                                    setFilterRequest("")
                                                }}
                                            >
                                                <Close/>
                                            </IconButton>
                                        </InputAdornment>
                                    ) : undefined
                            }}
                            value={searchText}
                            onChange={onSearchChange}
                        />
                        <Autocomplete
                            sx={{width: isMobile ? '100%' : 200, mb: isMobile ? 1 : 0}}
                            options={[
                                {display: "Active", value: 0, order: 1},
                                {display: "Completed", value: 1, order: 2},
                                {display: "Archived", value: 2, order: 3},
                            ]}
                            getOptionLabel={(option) => option.display}
                            onChange={(e, value) => setStatusSelected(value?.value)}
                            renderInput={(params) => <TextField {...params} sx={{backgroundColor: 'white', borderRadius: '20px'}} label="Status"/>}
                        />
                            <CustomDatePicker dateStart={dateStart} dateEnd={dateEnd} setDateStart={setDateStart}
                                              setDateEnd={setDateEnd} fullWidth={isMobile} />

                        <Button variant="contained" startIcon={<FileDownload />} fullWidth={isMobile} sx={{mt: isMobile ? 1 : 0}}>Export CSV</Button>
                    </Box>
                </div>
            </Container>
            <Container maxWidth="xl" sx={{marginTop: 1, marginBottom: 4}}>
                <Box sx={{
                    borderRadius: '20px',
                    backgroundColor: '#ffffff'
                }}>
                <CardContent>
                    <TableContainer sx={{maxHeight: 400}}>
                        <Table stickyHeader>
                            <TableHead>
                                <TableRow>
                                    <TableCell>Project</TableCell>
                                    <TableCell>Fielding Request</TableCell>
                                    <TableCell>Assigned Fielder</TableCell>
                                    <TableCell>Last Update</TableCell>
                                    <TableCell>Mileage</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {dataMileageList?.map((data, index) => (
                                    <TableRow key={`mileage-${index}`} className="hover">
                                        <TableCell>{data.project || '-'}</TableCell>
                                        <TableCell>{data.fieldingRequest || '-'}</TableCell>
                                        <TableCell>{data.assignedFielder}</TableCell>
                                        <TableCell>{data.lastUpdate}</TableCell>
                                        <TableCell>{data.mileage}</TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <Box mt={2}>
                        <Alert icon={false} severity="info" style={{borderRadius: '10px'}} sx={{
                            //MuiAlert-message
                            '& .MuiAlert-message': {
                                display: 'flex',
                                width: '100%',
                                justifyContent: 'space-between',
                                alignItems: 'center',
                            }
                        }}>
                            <Grid display={'flex'} width={'100%'}>
                                <Typography fontWeight={600} color={'#0b75bb'} display={'flex'} flexGrow={1}>
                                    Total Miles
                                </Typography>
                                <Typography fontWeight={600} color={'#0b75bb'}>
                                    {dataMileageList.reduce((a, b) => a + parseFloat(b.mileage), 0).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")} Miles
                                </Typography>
                            </Grid>
                        </Alert>
                    </Box>
                </CardContent>
                </Box>
                {/*<table className="custom-table" style={{marginTop: 0}}>*/}
                {/*    <thead style={{display: 'table', width: '100%', tableLayout: 'fixed'}}>*/}
                {/*    <tr>*/}
                {/*        /!*<th>Project</th>*!/*/}
                {/*        /!*<th>Fielding Request</th>*!/*/}
                {/*        <th>Project</th>*/}
                {/*        <th>Fielding Req</th>*/}
                {/*        <th>Assigned Fielder</th>*/}
                {/*        <th>Last Update</th>*/}
                {/*        <th>Mileage</th>*/}
                {/*    </tr>*/}
                {/*    </thead>*/}
                {/*    <tbody style={{display: 'block', height: '300px', overflow: 'auto'}}>*/}
                {/*    {dataMileageList?.map((data, index) => (*/}
                {/*        <tr key={`mileage-${index}`} className="hover"*/}
                {/*            style={{display: 'table', width: '100%', tableLayout: 'fixed'}}>*/}
                {/*            <td>{data.project || '-'}</td>*/}
                {/*            <td>{data.fieldingRequest || '-'}</td>*/}
                {/*            <td>{data.assignedFielder}</td>*/}
                {/*            <td>{data.lastUpdate}</td>*/}
                {/*            <td>{data.mileage}</td>*/}
                {/*        </tr>*/}
                {/*    ))}*/}
                {/*    </tbody>*/}
                {/*    <tfoot style={{display: 'table', width: '100%', tableLayout: 'fixed', marginTop: '20px'}}>*/}
                {/*    <tr>*/}
                {/*        <td colSpan={7}>*/}
                {/*            <div>*/}
                {/*                <Alert icon={false} severity="info" style={{borderRadius: '10px'}} sx={{*/}
                {/*                    //MuiAlert-message*/}
                {/*                    '& .MuiAlert-message': {*/}
                {/*                        display: 'flex',*/}
                {/*                        width: '100%',*/}
                {/*                        justifyContent: 'space-between',*/}
                {/*                        alignItems: 'center',*/}
                {/*                    }*/}
                {/*                }}>*/}
                {/*                    <Grid display={'flex'} width={'100%'}>*/}
                {/*                        <Typography fontWeight={600} color={'#0b75bb'} display={'flex'} flexGrow={1}>*/}
                {/*                            Total Miles*/}
                {/*                        </Typography>*/}
                {/*                        <Typography fontWeight={600} color={'#0b75bb'}>*/}
                {/*                            {dataMileageList.reduce((a, b) => a + parseFloat(b.mileage), 0).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")} Miles*/}
                {/*                        </Typography>*/}
                {/*                    </Grid>*/}
                {/*                </Alert>*/}
                {/*            </div>*/}
                {/*        </td>*/}
                {/*    </tr>*/}
                {/*    </tfoot>*/}
                {/*</table>*/}
            </Container>
        </div>
    );
};

export default Mileage;