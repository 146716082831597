import {
    Alert,
    Box,
    Button,
    Card,
    CardActions,
    CardContent,
    Container,
    FormControlLabel,
    MenuItem,
    Select,
    Typography
} from "@mui/material";
import React, {useEffect, useRef, useState} from "react";
import FieldingList from "./FieldingList";
import FieldingClock from "./FIeldingClock";
import FieldingMap from "./FieldingMap";
import CustomModal from "../../../shared/customModal/CustomModal";
import ExpenseCreate from "../../expense/component/ExpenseCreate";
import {useDispatch} from "react-redux";
import {resetDataDetail} from "../../expense/expense.reducer";
import {userInfo} from "../../../shared/utils";
import Swal from "sweetalert2";
import moment from "moment/moment";

import LockClockIcon from '@mui/icons-material/LockClock';

import AlertTitle from '@mui/material/AlertTitle';

export default function Fielding() {

    const dispatch = useDispatch<any>()
    const mapContainerRef = useRef<any>()

    const [viewMode, setViewMode] = useState<"LIST" | "MAP">("LIST")
    const [modalExpense, setModalExpense] = useState(false)

    const [hideCloseButton, setHideCloseButton] = useState(false)
    const [isLocked, setIsLocked] = useState(false)

    useEffect(() => {
        if (moment) {
            let clock24 = moment().format("MM/DD/YYYY HH:mm:ss")
            const isFielder = userInfo().user.roles.some((role: string) => role === "FielderSimple")
            let hour = clock24?.split(" ")[1]?.split(":")[0]
            if (isFielder && (parseInt(hour) >= 19 || parseInt(hour) < 3)) {
                setIsLocked(true)
            } else {
                setIsLocked(false)
            }
        }
    }, [moment])

    // useEffect(() => {
    //     if (isLocked) {
    //         Swal.fire({
    //             icon: "warning",
    //             title: "System Lock Schedule",
    //             text: "The system is locked daily from 7:00 PM to 3:00 AM. Please ensure all updates are made in real-time during work hours while in the field.",
    //             showConfirmButton: true,
    //             confirmButtonText: "OK",
    //         })
    //     }
    // }, [isLocked]);

    const onReloadClicked = () => {
        window.location.reload()
    }

    return (
        <Box sx={{position: 'relative'}}>
            <CustomModal
                open={modalExpense}
                onClose={() => {
                    setModalExpense(false)
                    dispatch(resetDataDetail())
                }}
                title={hideCloseButton ? "Expenses - Picture of Your Receipt" : "Expenses"}
                component={<ExpenseCreate onSubmit={() => {
                    setModalExpense(false)
                    dispatch(resetDataDetail())
                }} onHideCloseButton={(hide) => {
                    setHideCloseButton(hide)
                }} />}
                size={"lg"}
                fullHeight={false}
                hideCloseButton={hideCloseButton}
            />

            {
                isLocked && (
                    <Box sx={{
                        display: 'flex',
                        position: 'absolute',
                        width: '100%',
                        height: '100%',
                        justifyContent: 'center',
                        alignItems: 'center',
                        zIndex: 9
                    }}>
                        <Card sx={{ width: 350, height: 320, textAlign: "center", margin: 2 }}>
                            <CardContent>
                                <LockClockIcon sx={{ fontSize: 120, color: "#f8bb86" }} />
                                <Typography variant="h5" component="div" sx={{ marginBottom: 2 }}>
                                    System Lock Schedule
                                </Typography>
                                <Typography>
                                    The system is locked daily from 7:00 PM to 3:00 AM. Please ensure all updates are made in real-time during work hours while in the field.
                                </Typography>
                            </CardContent>
                            <CardActions sx={{ justifyContent: "center" }}>
                                <Button variant={'contained'} size="small" onClick={onReloadClicked}>OK</Button>
                            </CardActions>
                        </Card>
                    </Box>
                )
            }

            <Container
                maxWidth={"xl"}
                sx={{
                    marginY: 2,
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    flexWrap: "wrap",
                    gap: 1
                }}
            >
                <FormControlLabel
                    label="View Mode: "
                    labelPlacement="start"
                    componentsProps={{ typography: { whiteSpace: "nowrap" } }}
                    control={
                        <Select
                            fullWidth
                            size="small"
                            value={viewMode}
                            onChange={(e) => setViewMode(e.target.value as unknown as "LIST" | "MAP")}
                            sx={{ marginLeft: 1 }}
                        >
                            <MenuItem value={"LIST"}>List View</MenuItem>
                            <MenuItem value={"MAP"}>Map View</MenuItem>
                        </Select>
                    }
                />
                <Button sx={{ marginLeft: 1 }} variant="contained" onClick={() => setModalExpense(true)}>Expenses</Button>
                <Box display={"flex"} flexGrow={1}></Box>
                <FieldingClock />
            </Container>
            {viewMode === "LIST" && (
                <Box ref={mapContainerRef}>
                    <FieldingList />
                </Box>
            )}
            {viewMode === "MAP" && (
                <Box
                    ref={mapContainerRef}
                    display={"flex"}
                    flexGrow={1}
                    minHeight={window.innerHeight - mapContainerRef.current?.getBoundingClientRect().y}
                >
                    <FieldingMap />
                </Box>
            )}
        </Box>
    )
}