import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { baseUrl } from "../../state";
import handleError from "../../shared/handleError";
import { userInfo } from "../../shared/utils";
import moment from "moment";

export const getDashboardFieldingStatus = createAsyncThunk('getDashboardFieldingStatus', async (data: { filterStartDate: string, filterEndData: string }) => {
    try {
        const response = await axios({
            method: 'post',
            url: baseUrl + '/newapi/Dashboard/FPGetDashboardFieldingRequestData',
            data: {
                token: userInfo().token,
                filterStartDate: moment(data.filterStartDate).format('MM/DD/YYYY'),
                filterEndDate: moment(data.filterEndData).format('MM/DD/YYYY')
            }
        });
        return response;
    } catch (error: any) {
        handleError(error);
        return error.response;
    }
});

export const getDashboardFieldingDate = createAsyncThunk('getDashboardFieldingDate', async (data: { filterStartDate: string, filterEndData: string }) => {
    try {
        const response = await axios({
            method: 'post',
            url: baseUrl + '/newapi/Dashboard/FPGetDashboardFieldingRequestDataByDate',
            data: {
                token: userInfo().token,
                filterStartDate: moment(data.filterStartDate).format('MM/DD/YYYY'),
                filterEndDate: moment(data.filterEndData).format('MM/DD/YYYY')
            }
        });
        return response;
    } catch (error: any) {
        handleError(error);
        return error.response;
    }
});

export const getDashboardFieldingType = createAsyncThunk('getDashboardFieldingType', async (data: { filterStartDate: string, filterEndData: string }) => {
    try {
        const response = await axios({
            method: 'post',
            url: baseUrl + '/newapi/Dashboard/FPGetDashboardFieldingRequestDataByType',
            data: {
                token: userInfo().token,
                filterStartDate: moment(data.filterStartDate).format('MM/DD/YYYY'),
                filterEndDate: moment(data.filterEndData).format('MM/DD/YYYY')
            }
        });
        return response;
    } catch (error: any) {
        handleError(error);
        return error.response;
    }
});

export const getDashboardFielderActive = createAsyncThunk('getDashboardFielderActive', async (data: { filterStartDate: string, filterEndData: string }) => {
    try {
        const response = await axios({
            method: 'post',
            url: baseUrl + '/newapi/Dashboard/FPGetDashboardActiveFielders',
            data: {
                token: userInfo().token,
                filterStartDate: moment(data.filterStartDate).format('MM/DD/YYYY'),
                filterEndDate: moment(data.filterEndData).format('MM/DD/YYYY')
            }
        });
        return response;
    } catch (error: any) {
        handleError(error);
        return error.response;
    }
});

export const getDashboardActivity = createAsyncThunk('getDashboardActivity', async (data: { filterStartDate: string, filterEndDate: string, activityType: number | undefined, fielderId: string | undefined }) => {
    try {
        const response = await axios({
            method: 'post',
            url: baseUrl + '/newapi/Dashboard/FPGetDashboardActivityLogs',
            data: {
                ...data,
                token: userInfo().token
            }
        });
        return response;
    } catch (error: any) {
        handleError(error);
        return error.response;
    }
});

export const getDashboardActivityType = createAsyncThunk('getDashboardActivityType', async () => {
    try {
        const response = await axios({
            method: 'get',
            url: baseUrl + '/newapi/Dashboard/GetActivityTypeEnum',
            data: {
                token: userInfo().token
            }
        });
        return response;
    } catch (error: any) {
        handleError(error);
        return error.response;
    }
});

export const getDashboardFielderNotes = createAsyncThunk('getDashboardFielderNotes', async (data: { filterStartDate: string, filterEndData: string }) => {
    try {
        const response = await axios({
            method: 'post',
            url: baseUrl + '/newapi/Dashboard/FPGetDashboardFieldingRequestNotes',
            data: {
                token: userInfo().token,
                filterStartDate: moment(data.filterStartDate).format('MM/DD/YYYY'),
                filterEndDate: moment(data.filterEndData).format('MM/DD/YYYY')
            }
        });
        return response;
    } catch (error: any) {
        handleError(error);
        return error.response;
    }
});

export const getDashboardRequestProject = createAsyncThunk('getDashboardRequestProject', async (data: { filterStartDate: string, filterEndData: string, status: number }) => {
    try {
        const response = await axios({
            method: 'post',
            url: baseUrl + '/newapi/Dashboard/FPGetDashboardFieldingRequestProjectData',
            data: {
                token: userInfo().token,
                filterStartDate: moment(data.filterStartDate).format('MM/DD/YYYY'),
                filterEndDate: moment(data.filterEndData).format('MM/DD/YYYY'),
                status: data.status
            }
        });
        return response;
    } catch (error: any) {
        handleError(error);
        return error.response;
    }
});

export const getDashboardActiveFielderDetails = createAsyncThunk('getDashboardActiveFielderDetails', async (data: { filterStartDate: string, filterEndData: string, targetFielderId: string }) => {
    try {
        const response = await axios({
            method: 'post',
            url: baseUrl + '/newapi/Dashboard/FPGetActiveFielderDetails',
            data: {
                token: userInfo().token,
                filterStartDate: moment(data.filterStartDate).format('MM/DD/YYYY'),
                filterEndDate: moment(data.filterEndData).format('MM/DD/YYYY'),
                targetFielderId: data.targetFielderId
            }
        });
        return response;
    } catch (error: any) {
        handleError(error);
        return error.response;
    }
});

export const getDashboardProject = createAsyncThunk('getDashboardProject', async (data: { filterStartDate: string, filterEndData: string, status: number, area?: string, customer?: string, assignedFielderId?: string }) => {
    try {
        const response = await axios({
            method: 'post',
            url: baseUrl + '/newapi/Dashboard/FPGetDashboardFieldingRequestProjectDataByDate',
            data: {
                token: userInfo().token,
                filterStartDate: moment(data.filterStartDate).format('MM/DD/YYYY'),
                filterEndDate: moment(data.filterEndData).format('MM/DD/YYYY'),
                status: data.status,
                area: data.area,
                customer: data.customer,
                assignedFielderId: data.assignedFielderId
            }
        });
        return response;
    } catch (error: any) {
        handleError(error);
        return error.response;
    }
});

export const getDashboardManager = createAsyncThunk('getDashboardManager', async (data: { filterStartDate: string, filterEndDate: string, filter: string | undefined, fielderId: string | undefined, projectStatus: number | undefined }) => {
    try {
        const response = await axios({
            method: 'post',
            url: baseUrl + '/newapi/Dashboard/FPGetManagerLogs',
            data: {
                ...data,
                token: userInfo().token
            }
        });
        return response;
    } catch (error: any) {
        handleError(error);
        return error.response;
    }
});