import { Box, Button, TextField, Typography } from "@mui/material";
import { useState } from "react";

export default function ModalProjectReactive(props: { id: string | null, onCancel: () => void, onSubmit: (notes: string) => void}) {

    const [reason, setReason] = useState("")

    const handleSubmit = async () => {
        if (props.id !== null) {
            props.onSubmit(reason)
        }
    }

    return (
        <Box display={"flex"} flexDirection={"column"} gap={1}>
            <Typography variant="h6">Rejected Message</Typography>
            <TextField multiline minRows={5} value={reason} onChange={(e) => setReason(e.target.value)} />
            <Button variant="contained" fullWidth color="primary" disabled={reason === ""} onClick={handleSubmit}>Rejected</Button>
            <Button variant="contained" fullWidth color="secondary" onClick={props.onCancel}>Cancel</Button>
        </Box>
    )
}