import {
    AssignmentTurnedIn,
    Construction,
    Edit,
    ExpandLess,
    ExpandMore,
    FolderOpen,
    Image,
    PlayArrowOutlined
} from "@mui/icons-material";
import {
    Box,
    Button,
    Card,
    CardContent,
    Chip,
    Collapse,
    Container,
    Divider,
    Grid,
    IconButton,
    Tab,
    Tabs,
    Typography,
    useMediaQuery,
    useTheme
} from "@mui/material";
import {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {RootState} from "../../../store";
import {fieldingAssignedGet} from "../fielding.api";
import {
    InterfaceFieldingData,
    InterfaceFieldingDataByDistance,
    InterfaceFieldingDataDetail
} from "../fielding.interface";
import CustomModal from "../../../shared/customModal/CustomModal";
import FieldingNotes from "./FieldingNotes";
import FieldingUploadImages from "./FieldingUploadImages";
import {setStatusSelected} from "../fielding.reducer";
import progressColor from "../../../shared/progressColor";
import hot from "../../../assets/images/hot.png"
import FieldingStart from "./FieldingStart";
import haversine from "haversine";
import Swal from "sweetalert2";
import RoomIcon from '@mui/icons-material/Room';
import moment from "moment/moment";

export default function FieldingList() {

    const theme = useTheme()
    const dispatch = useDispatch<any>()
    const isMobile = useMediaQuery("(max-width:768px)")

    const {
        statusSelected,
        dataList: originDataList,
        // dataByDistance: dataList
    } = useSelector((state: RootState) => state.fielding)

    const [idExpandedList, setIdExpandedList] = useState<string[]>([])
    const [dataSelected, setDataSelected] = useState<InterfaceFieldingDataDetail | null>(null)
    const [modalNotes, setModalNotes] = useState(false)
    const [modalImages, setModalImages] = useState(false)
    const [isNearestFeature, setIsNearestFeature] = useState(false)

    const [dataList, setDataList] = useState<InterfaceFieldingDataByDistance[]>([]);

    useEffect(() => {
        dispatch(fieldingAssignedGet(statusSelected))
    }, [dispatch, statusSelected])

    const [location, setLocation] = useState<{ latitude: number, longitude: number } | null>(null);

    useEffect(() => {
        if ("geolocation" in navigator) {
            navigator.geolocation.getCurrentPosition(
                (position) => {
                    setLocation({
                        latitude: position.coords.latitude,
                        longitude: position.coords.longitude,
                    });
                },
                (err) => {
                    Swal.fire({
                        icon: "warning",
                        html: err.message,
                        showConfirmButton: true,
                        showCancelButton: false,
                        confirmButtonText: "OK",
                    })
                }
            );
        } else {
            Swal.fire({
                icon: "warning",
                html: `Geolocation is not supported by your browser`,
                showConfirmButton: true,
                showCancelButton: false,
                confirmButtonText: "OK",
            })
        }
    }, []);

    useEffect(() => {
        if (location) {
            // add distance
            let dataWithDistance: InterfaceFieldingDataByDistance[] = originDataList.map((item: InterfaceFieldingData) => ({
                ...item,
                details: item.details.map((detail: any) => ({
                    ...detail,
                    distance: haversine({
                        latitude: location.latitude,
                        longitude: location.longitude
                    }, {
                        longitude: parseFloat(detail.firstMarkerLocation.split(',')[0]),
                        latitude: parseFloat(detail.firstMarkerLocation.split(',')[1])
                    }, {unit: 'mile'})
                }))
            }))

            // sort dataWithDistance details distance from lower to higher
            dataWithDistance.forEach((item: any) => {
                item.details.sort((a: any, b: any) => a.distance - b.distance);
            })

            // sort dataWithDistance distance from lower to higher
            dataWithDistance.sort((a: any, b: any) => a.details[0].distance - b.details[0].distance);

            let filteredData = dataWithDistance;
            let nearestData: any = undefined;
            let dataReactivated: any = undefined;

            if (dataWithDistance.length > 0) {
                nearestData = {...dataWithDistance[0], isNearest: true, isReactivated: dataWithDistance[0].details.some((detail: any) => detail.isReactivated)};
                filteredData = dataWithDistance.filter((item: any) => item.projectId !== nearestData.projectId);

                // details some isReactivated
                dataReactivated = filteredData.filter((item: any) => item.details.some((detail: any) => detail.isReactivated))?.map((item: any) => {
                    return {
                        ...item,
                        isReactivated: true,
                }});

                const dataSorted = filteredData.map((item: any) => {
                    return {
                        ...item,
                        details: item.details.sort((a: any, b: any) => moment(a?.dueDate, 'MM/DD/YYYY').diff(moment(b?.dueDate, 'MM/DD/YYYY')))
                    }
                })

                filteredData = [...dataSorted].sort((a: any, b: any) => moment(a?.details[0]?.dueDate, 'MM/DD/YYYY').diff(moment(b?.details[0]?.dueDate, 'MM/DD/YYYY')))
            }

            let _data = nearestData ? [nearestData, ...filteredData] : dataWithDistance;
            _data = dataReactivated ? [...dataReactivated, ..._data.filter((item: any) => !dataReactivated.map((item: any) => item.projectId).includes(item.projectId))] : _data;

            // setDataList(nearestData ? [nearestData, ...filteredData] : dataWithDistance);
            setDataList(_data);
            setIsNearestFeature(true);
        } else {
            // add distance
            let dataWithDistance: InterfaceFieldingDataByDistance[] = originDataList.map((item: InterfaceFieldingData) => ({
                ...item,
                details: item.details.map((detail: any) => ({
                    ...detail,
                    distance: 0
                }))
            }))
            // details some isReactivated
            let dataReactivated = dataWithDistance.filter((item: any) => item.details.some((detail: any) => detail.isReactivated))?.map((item: any) => {
                return {
                    ...item,
                    isReactivated: true,
                }
            });

            let filteredData = dataWithDistance.filter((item: any) => !dataReactivated.map((item: any) => item.projectId).includes(item.projectId));

            setDataList([...dataReactivated, ...filteredData]);
            setIsNearestFeature(false);
        }
    }, [originDataList, location]);

    console.log('data list', dataList);

    useEffect(() => {
        if (dataSelected !== null) {
            const projectSelected = dataList.find(data => data.details.map(detail => detail.fieldingRequestId).includes(dataSelected.fieldingRequestId))
            if (projectSelected) {
                const detailSelected = projectSelected.details.find(detail => detail.fieldingRequestId === dataSelected.fieldingRequestId)
                if (detailSelected) {
                    if (JSON.stringify(detailSelected) !== JSON.stringify(dataSelected)) {
                        setDataSelected(detailSelected)
                    }
                }
            }
        }
    }, [dataList, dataSelected])

    const toggleExpand = (id: string) => {
        if (idExpandedList.includes(id)) {
            setIdExpandedList(idExpandedList.filter(i => i !== id))
        } else {
            setIdExpandedList([...idExpandedList, id])
        }
    }

    const statusList = [
        {
            name: "Active Jobs",
            value: 0,
            icon: <Construction/>
        },
        {
            name: "Completed",
            value: 1,
            icon: <AssignmentTurnedIn/>
        },
        {
            name: "Archived",
            value: 2,
            icon: <FolderOpen/>
        }
    ]



    const onClaimClicked = (fieldingRequestId: string) => {
        // check localstorage if fieldingRequestId is already claimed
        const claimedFieldingRequest = localStorage.getItem("claimedFieldingRequest");
        const claimedFieldingRequestList = JSON.parse(claimedFieldingRequest ?? "[]");

        // make it claim / unclaim
        if (claimedFieldingRequestList.includes(fieldingRequestId)) {
            const newClaimedFieldingRequestList = claimedFieldingRequestList.filter((item: string) => item !== fieldingRequestId);
            localStorage.setItem("claimedFieldingRequest", JSON.stringify(newClaimedFieldingRequestList));
            dispatch(fieldingAssignedGet(statusSelected));
        } else {
            const newClaimedFieldingRequestList = [...claimedFieldingRequestList, fieldingRequestId];
            localStorage.setItem("claimedFieldingRequest", JSON.stringify(newClaimedFieldingRequestList));
            dispatch(fieldingAssignedGet(statusSelected));
        }
    }

    return (
        <Container maxWidth="xl">
            <CustomModal
                open={Boolean(dataSelected) && modalNotes}
                onClose={() => {
                    setDataSelected(null);
                    setModalNotes(false)
                }}
                title="Notes"
                size="sm"
                component={dataSelected !== null && <FieldingNotes fieldingRequestId={dataSelected?.fieldingRequestId}
                                                                   notes={dataSelected?.fieldingRequestNotes}
                                                                   onUpdateData={() => dispatch(fieldingAssignedGet(statusSelected))}/>}
            />
            <CustomModal
                open={Boolean(dataSelected) && modalImages}
                onClose={() => {
                    setDataSelected(null);
                    setModalImages(false)
                }}
                title="Upload File"
                size="sm"
                component={dataSelected !== null &&
                    <FieldingUploadImages fieldingId={dataSelected?.fieldingRequestId} onClose={() => {
                        setDataSelected(null);
                        setModalImages(false)
                    }}/>}
            />
            <Box
                bgcolor={theme.palette.background.default}
                borderRadius={1}
                marginBottom={2}
            >
                <Box>
                    <Tabs variant={isMobile ? "fullWidth" : "standard"} value={statusSelected}
                          onChange={(e, value) => dispatch(setStatusSelected(value))}>
                        {statusList.map((status, statusIndex) => (
                            <Tab
                                key={statusIndex}
                                label={status.name}
                                value={status.value}
                                icon={status.icon}
                                iconPosition="start"
                                sx={{paddingY: 0}}
                            />
                        ))}
                    </Tabs>
                </Box>
                <Divider/>
                <Box
                    padding={2}
                    display={"flex"}
                    flexDirection={"column"}
                    gap={1}
                >
                    {dataList.map((fielding, fieldingIndex) => (
                        <Card key={fieldingIndex} variant="outlined">
                            <CardContent>
                                <Box display="flex" alignItems={"center"} justifyContent="space-between"
                                     sx={{cursor: "pointer"}} onClick={() => toggleExpand(fielding.projectId)}>
                                    <Grid container>
                                        <Grid item xs={12} md={4}>
                                            <Typography variant="h6" color={fielding.isHotJob ? "red" : undefined}>
                                                {fielding.title}
                                                {fielding.isHotJob && (
                                                    <img src={hot} alt="hot" style={{
                                                        width: "20px",
                                                        marginLeft: 4,
                                                        verticalAlign: "middle"
                                                    }}/>
                                                )}
                                                {
                                                    fielding?.isReactivated && (
                                                        <Chip label={`REJECTED`}
                                                              sx={{ backgroundColor: '#8b0000', color: '#ffffff', fontWeight: 'bold', ml: 2, mr: 1, mb: isMobile ? 1 : 0}}/>
                                                    )
                                                }
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={10} md={6} display={isMobile ? "block" : "flex"}
                                              mt={isMobile ? 2 : 0}>

                                            <Chip label={`${fielding.fieldingRequestCount} Request`}
                                                  sx={{mr: 1, mb: isMobile ? 1 : 0}}/>

                                            {(isNearestFeature && fielding?.isNearest && statusSelected === 0) &&
                                                <Chip icon={<RoomIcon/>}
                                                      label={`Job closest to your location (${fielding.details[0]?.distance.toFixed(2)} miles)`}
                                                      color={"success"}/>}
                                        </Grid>
                                        <Grid item xs={2} display={"flex"} justifyContent={"flex-end"}>
                                            <IconButton>
                                                {idExpandedList.includes(fielding.projectId)
                                                    ? <ExpandLess/>
                                                    : <ExpandMore/>
                                                }
                                            </IconButton>
                                        </Grid>
                                    </Grid>
                                </Box>
                                <Collapse in={idExpandedList.includes(fielding.projectId)}>
                                    <Box display={"flex"} flexDirection={"column"} gap={1} marginTop={2}>
                                        {fielding.details.map((detail, detailIndex) => (
                                            <Box key={detailIndex} bgcolor={"#f8f8f8"} padding={2} borderRadius={1}>
                                                <Grid container spacing={2}>
                                                    <Grid item xs={6} md={2}>
                                                        <Typography fontWeight={"bold"}>Due Date</Typography>
                                                        <Typography fontWeight={"light"}>{detail.dueDate}</Typography>
                                                    </Grid>
                                                    <Grid item xs={6} md={2}>
                                                        <Typography fontWeight={"bold"}>Request Number</Typography>
                                                        <Typography
                                                            fontWeight={"light"}>{detail.fieldingRequest}</Typography>
                                                    </Grid>
                                                    <Grid item xs={6} md={2}>
                                                        <Typography fontWeight={"bold"}>Request Date</Typography>
                                                        <Typography fontWeight={"light"}>{detail.createdDate}</Typography>
                                                    </Grid>
                                                    <Grid item xs={12} md={2}>
                                                        <Grid container spacing={1}>
                                                            <Grid item xs={6} md={12}>
                                                                <Typography
                                                                    color={progressColor(detail.averageProgressPercentage)}>Progress: {detail.averageProgressPercentage.toFixed(2)}%</Typography>
                                                            </Grid>
                                                            <Grid item xs={6} md={12}>
                                                                <Typography>Total
                                                                    Miles: {detail.milesTotal}</Typography>
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                    <Grid item xs={12} md={4}>
                                                        <Grid container spacing={1}>
                                                            {statusSelected !== 0 && (
                                                                <Grid item xs={12} lg={3}>
                                                                </Grid>
                                                            )}
                                                            <Grid item xs={6} sm={4} md={6} lg={3}>
                                                                <Button fullWidth variant="contained" color="inherit"
                                                                        startIcon={<Image/>} onClick={() => {
                                                                    setDataSelected(detail);
                                                                    setModalImages(true)
                                                                }}>Upload</Button>
                                                            </Grid>
                                                            <Grid item xs={6} sm={4} md={6} lg={3}>
                                                                <a href={`https://www.google.com/maps/search/?api=1&query=${detail.firstMarkerLocation.split(',')[1]}%2C${detail.firstMarkerLocation.split(',')[0]}`}
                                                                   target="_blank">
                                                                    <Button fullWidth variant="contained"
                                                                            startIcon={<PlayArrowOutlined/>}
                                                                            sx={{bgcolor: '#4ADEDE'}}>Direction</Button>
                                                                </a>
                                                            </Grid>
                                                            <Grid item xs={6} sm={4} md={6} lg={3}>
                                                                <Button fullWidth variant="contained"
                                                                        startIcon={<Edit/>} sx={{bgcolor: '#1560BD'}}
                                                                        onClick={() => {
                                                                            setDataSelected(detail);
                                                                            setModalNotes(true)
                                                                        }}>Notes</Button>
                                                            </Grid>
                                                            <Grid item xs={6} sm={4} md={6} lg={3}>
                                                                <Button fullWidth variant="contained" color="inherit"
                                                                        onClick={() => {
                                                                            onClaimClicked(detail.fieldingRequestId)
                                                                        }} sx={{bgcolor: JSON.parse(localStorage.getItem('claimedFieldingRequest') ?? "[]").includes(detail.fieldingRequestId) ? '#ffeb3b' :'#ffffff'}}>{
                                                                    JSON.parse(localStorage.getItem('claimedFieldingRequest') ?? "[]").includes(detail.fieldingRequestId) ? "Claimed" : "Claim"
                                                                }</Button>
                                                            </Grid>
                                                            {statusSelected === 0 && (
                                                                <Grid item xs={6} sm={4} md={6} lg={3}>
                                                                    <FieldingStart id={detail.fieldingRequestId}/>
                                                                </Grid>
                                                            )}
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            </Box>
                                        ))}
                                    </Box>
                                </Collapse>
                            </CardContent>
                        </Card>
                    ))}
                </Box>
            </Box>
        </Container>
    )
}