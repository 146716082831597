import { Autocomplete, Box, Button, Card, CardHeader, Checkbox, Divider, FormControlLabel, Grid, List, ListItem, ListItemButton, ListItemIcon, TextField, Typography, useMediaQuery } from "@mui/material"
import ProjectAssignMap from "./ProjectAssignMap"
import { InitialFieldingDetail, InterfaceFieldingDetail, InterfaceMarkerLine, InterfaceMarkerPoint } from "../../fielding/fielding.interface"
import { ArrowBack, ChangeHistory, Circle, Grade, Star } from "@mui/icons-material"
import CheckBoxIcon from "@mui/icons-material/CheckBox"
import { useNavigate, useSearchParams } from "react-router-dom"
import { ChangeEvent, Fragment, useEffect, useState } from "react"
import { fieldingAssignFtr, fieldingDetailsGet, fieldingEdit, fieldingTypeGet } from "../../fielding/fielding.api"
import { useDispatch, useSelector } from "react-redux"
import { RootState } from "../../../store"
import { InterfaceAccountFielderDetailData, InterfaceAccountFielderDetailDataList } from "../../account/account.interface"
import { formatName, userInfo } from "../../../shared/utils"
import moment from "moment"
import { getAccountFielder, getAccountFielderDetailList } from "../../account/account.api"
import { colors } from "../../../state"
import randomColor from "randomcolor"
import Swal from "sweetalert2"

import { styled } from "@mui/material/styles"
import Tooltip, { TooltipProps, tooltipClasses } from "@mui/material/Tooltip"
import notificationSuccess from "../../../shared/notificationSuccess"
import useAddress from "../../../shared/useAddress"

export interface ProjectAssignProps {
    isMiniAssign?: boolean;
    id?: string | undefined;
    onMultipleAssign?: (isAssignFtr: boolean, fieldingRequest: InterfaceFieldingDetail, selectedAssign: Array<string>) => void;
}

export default function ProjectAssign({
    isMiniAssign = false,
    id = undefined,
    ...props
                                      }: ProjectAssignProps) {
    const dispatch = useDispatch<any>()
    const navigate = useNavigate()
    const [searchParams] = useSearchParams()
    const isMobile = useMediaQuery("(max-width:768px)")
    const address = useAddress()

    const { dataFielderDetailList } = useSelector((state: RootState) => state.account)
    const { dataTypeList } = useSelector((state: RootState) => state.fielding)

    const [fielderDetailList, setFielderDetailList] = useState<InterfaceAccountFielderDetailDataList>([])
    const [fieldingRequest, setFieldingRequest] = useState<InterfaceFieldingDetail>(InitialFieldingDetail)
    const [fieldingRequestOriginal, setFieldingRequestOriginal] = useState<InterfaceFieldingDetail>(InitialFieldingDetail)
    const [fielderSelectedIds, setFielderSelectedIds] = useState<Array<string>>([])
    const [fielderHoverId, setFielderHoverId] = useState("")
    const [startLocationList, setStartLocationList] = useState<Array<{ id: string; state: string; point: InterfaceMarkerPoint }>>([])
    const [fieldingState, setFieldingState] = useState("")
    const [FirstFieldingRequest, setFirstFieldingRequest] = useState<InterfaceFieldingDetail>(InitialFieldingDetail)
    const [SelectedAssign, setSelectedAssign] = useState<Array<string>>([])
    const [typeSelected, setTypeSelected] = useState<string | null>(null)
    const [isAssignFtr, setIsAssignFtr] = useState<boolean>(false)

    useEffect(() => {
        getDetails()
        dispatch(fieldingTypeGet())
        dispatch(getAccountFielder())
    }, [isMiniAssign, id])

    useEffect(() => {
        setIsAssignFtr(fieldingRequest?.ftrFielderQuantity > 0)
    }, [fieldingRequest])

    useEffect(() => {
        if (Boolean(fieldingRequest.requestedStartDateString)) {
            dispatch(
                getAccountFielderDetailList({
                    token: userInfo().token,
                    companyId: userInfo().user.companyId,
                    skillTypeId: typeSelected,
                    startDateFilter:
                        fieldingRequest.requestedStartDateString === "-" ||
                            fieldingRequest.requestedStartDateString === ""
                            ? ""
                            : moment(fieldingRequest.requestedStartDateString).format("MM/DD/YYYY"),
                    endDateFilter:
                        fieldingRequest.requestedDueDateString === "-" || fieldingRequest.requestedDueDateString === ""
                            ? ""
                            : moment(fieldingRequest.requestedDueDateString).format("MM/DD/YYYY")
                })
            )
        }
    }, [typeSelected, fieldingRequest.requestedStartDateString, fieldingRequest.requestedDueDateString])

    useEffect(() => {
        if (fieldingRequest.requestedStartDateString !== "" || fieldingRequest.requestedDueDateString !== "") {
            let start = moment(fieldingRequest.requestedStartDateString).format("MM/DD/YYYY")
            let end = moment(fieldingRequest.requestedDueDateString).format("MM/DD/YYYY")
            dispatch(
                getAccountFielderDetailList({
                    token: userInfo().token,
                    companyId: userInfo().user.companyId,
                    skillTypeId: typeSelected,
                    startDateFilter:
                        fieldingRequest.requestedStartDateString === "-" ||
                            fieldingRequest.requestedStartDateString === ""
                            ? ""
                            : start,
                    endDateFilter:
                        fieldingRequest.requestedDueDateString === "-" || fieldingRequest.requestedDueDateString === ""
                            ? ""
                            : end
                })
            )
        }
    }, [FirstFieldingRequest])

    useEffect(() => {
        if (fielderDetailList.every((f) => f.addressLocation === undefined)) {
            getStartLocation()
        }
    }, [dataFielderDetailList])

    useEffect(() => {
        setFielderDetailList(
            dataFielderDetailList.map((fielder) => {
                return {
                    ...fielder,
                    addressLocation:
                        startLocationList.filter((location) => location.id === fielder.id).length > 0
                            ? startLocationList.filter((location) => location.id === fielder.id)[0].point
                            : undefined,
                    state:
                        startLocationList.filter((location) => location.id === fielder.id).length > 0
                            ? startLocationList.filter((location) => location.id === fielder.id)[0].state
                            : undefined
                }
            })
        )
    }, [dataFielderDetailList, startLocationList])

    useEffect(() => {
        if (fieldingRequest.firstMarkerLocation) {
            address.getAddress(parseFloat(fieldingRequest.firstMarkerLocation.split(",")[1]), parseFloat(fieldingRequest.firstMarkerLocation.split(",")[0]))
                .then((response) => {
                    setFieldingState(response.address)
                })
        }
    }, [fieldingRequest])

    const getStartLocation = async () => {
        const fielderNoLocation = dataFielderDetailList.filter(
            (fielder) => !startLocationList.map((location) => location.id).includes(fielder.id)
        )
        const locationToAdd: Array<{
            id: string
            state: string
            point: { latitude: number; longitude: number }
        }> = []
        for (const i in fielderNoLocation) {
            address.getCoordinate(dataFielderDetailList[i].address)
                .then(async (response) => {
                    address.getAddress(response.latitude, response.longitude)
                        .then((responseState) => {
                            locationToAdd.push({
                                id: fielderNoLocation[i].id,
                                state: responseState.city,
                                point: {
                                    latitude: response.latitude,
                                    longitude: response.longitude
                                }
                            })
                        })
                })
        }
        setStartLocationList(locationToAdd)
    }

    const handleChangeDate = (e: ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target
        setFieldingRequest((prev) => ({
            ...prev,
            [name]: value
        }))
        let startDate: string = fieldingRequest.requestedStartDateString
        let dueDate: string = fieldingRequest.requestedDueDateString
        if (name === "requestedStartDateString") {
            startDate = fieldingRequest.requestedStartDateString === "-" || fieldingRequest.requestedStartDateString === ""
                ? ""
                : moment(value).format("MM/DD/YYYY")
            dueDate = value ? moment(value).add(3, "day").format("MM/DD/YYYY") : value
        }
        else if (name === "requestedDueDateString") {
            if (value === "") {
                startDate = ""
            }
        }
        else {
            dueDate = fieldingRequest.requestedDueDateString === "-" || fieldingRequest.requestedDueDateString === ""
                ? ""
                : moment(value).format("MM/DD/YYYY")
        }
        let data = {
            colorCode: fieldingRequest.colorCode,
            customTypeId: fieldingRequest.customTypeId,
            dueDateString: moment(fieldingRequest.dueDateString).format("MM/DD/YYYY"),
            editedFieldingRequestMarkers: [],
            fielderIds: fieldingRequest.fielderIds,
            ftrFielderIds: fieldingRequest.ftrFielderIds,
            fieldingRequestId: fieldingRequest.id,
            requestedDueDateString: dueDate,
            requestedStartDateString: startDate,
            token: userInfo().token
        }
        dispatch(
            fieldingEdit({
                ...data,
                [name]: value !== "" ? moment(value).format("MM/DD/YYYY") : value,
                isEndMarker: false
            })
        )
            .unwrap()
            .then(() => {
                getDetails()
            })
    }

    const getDetails = () => {
        dispatch(fieldingDetailsGet(isMiniAssign ? id ?? "" : searchParams.get("id") || ""))
            .unwrap()
            .then((response: any) => {
                let data: InterfaceFieldingDetail = response.data.data
                setFirstFieldingRequest(data)
                let newData = {
                    ...data,
                    dueDateString: moment(data.dueDateString).format("YYYY-MM-DD"),
                    fieldingRequestMarkers: data.fieldingRequestMarkers.map((marker: any) => {
                        return {
                            ...marker,
                            lineData: JSON.parse(marker.lineData),
                            shapeData: JSON.parse(marker.shapeData)
                        }
                    })
                }

                if (SelectedAssign.length > 0) {
                    let ids = SelectedAssign.filter((selectedId: any) =>
                        data.fielderIds.length > 0 ? data.fielderIds.find((fid: any) => fid !== selectedId) : true
                    )
                    newData = { ...newData, ftrFielderIds: [...data.ftrFielderIds, ...ids] }
                }
                setFieldingRequest(newData)
                setFieldingRequestOriginal(newData)
            })
    }

    const handleChangeAssign = (value: boolean, fielderId: string) => {
        let newFtrFielderIds: any = []
        if (isAssignFtr) {
            if (value) {
                newFtrFielderIds = [...fieldingRequest.ftrFielderIds, fielderId]
            }
            else {
                newFtrFielderIds = fieldingRequest.ftrFielderIds.filter((d) => d !== fielderId)
            }
            setFieldingRequest((prev) => ({
                ...prev,
                fielderIds: fieldingRequest.fielderIds.filter((d) => d !== fielderId),
                fielderId,
                ftrFielderIds: newFtrFielderIds
            }))
        }
        else {
            if (value) {
                newFtrFielderIds = [
                    ...fieldingRequest.fielderIds,
                    ...fieldingRequest.ftrFielderIds.filter((d) => d !== fielderId),
                    fielderId
                ]
                setFieldingRequest((prev) => ({
                    ...prev,
                    ftrFielderIds: newFtrFielderIds
                }))
            }
            else {
                newFtrFielderIds = [
                    ...fieldingRequest.fielderIds.filter((d) => d !== fielderId),
                    ...fieldingRequest.ftrFielderIds.filter((d) => d !== fielderId)
                ]
                setFieldingRequest((prev) => ({
                    ...prev,
                    fielderIds: fieldingRequest.fielderIds.filter((d) => d !== fielderId),
                    ftrFielderIds: fieldingRequest.ftrFielderIds.filter((d) => d !== fielderId)
                }))
            }
        }

        let unique: any = []
        newFtrFielderIds.forEach((element: any) => {
            if (!unique.includes(element)) {
                unique.push(element)
            }
        })
        newFtrFielderIds = unique
        setSelectedAssign(newFtrFielderIds)
    }

    const handleChangeFtrQty = async (value: number) => {
        if (value === 0) {
            setSelectedAssign([])
            await dispatch(
                fieldingAssignFtr({
                    fielderIds: [],
                    fieldingRequestId: fieldingRequest.id,
                    token: userInfo().token,
                    ftrFielderQuantity: 0
                })
            ).unwrap()
            getDetails()
        } else {
            setFieldingRequest((prev) => ({
                ...prev,
                ftrFielderQuantity: value
            }))
        }
    }

    const handleSubmit = async () => {
        if (isMiniAssign) {
            Swal.fire({
                icon: 'question',
                title: 'Do you want to assign all request to this fielder?',
                showCancelButton: true,
                confirmButtonText: 'Yes',
                cancelButtonText: 'No',
            }).then(async (result) => {
                if (result.isConfirmed) {
                    props.onMultipleAssign && props.onMultipleAssign(isAssignFtr, fieldingRequest, SelectedAssign)
                } else {
                    handleSubmitSingleAssign()
                }
            })
        } else {
            handleSubmitSingleAssign()
        }
    }

    const handleSubmitSingleAssign = async () => {
        if (isAssignFtr) {
            await dispatch(
                fieldingAssignFtr({
                    fielderIds: fieldingRequest.ftrFielderIds,
                    fieldingRequestId: fieldingRequest.id,
                    token: userInfo().token,
                    ftrFielderQuantity: fieldingRequest.ftrFielderQuantity
                })
            )
                .unwrap()
                .then((res: any) => {
                    if (res.data.status) {
                        getDetails()
                    }
                    else {
                        notificationSuccess("")
                        getDetails()
                    }
                })
        } else handleSubmitDirect()
    }

    const handleSubmitDirect = async () => {
        let data = {
            colorCode: fieldingRequest.colorCode,
            customTypeId: fieldingRequest.customTypeId,
            dueDateString: moment(fieldingRequest.dueDateString).format("MM/DD/YYYY"),
            editedFieldingRequestMarkers: [],
            fielderIds: SelectedAssign,
            fieldingRequestId: fieldingRequest.id,
            requestedDueDateString:
                fieldingRequest.requestedDueDateString === "-" || fieldingRequest.requestedDueDateString === ""
                    ? ""
                    : moment(fieldingRequest.requestedDueDateString).format("MM/DD/YYYY"),
            requestedStartDateString:
                fieldingRequest.requestedStartDateString === "-" || fieldingRequest.requestedStartDateString === ""
                    ? ""
                    : moment(fieldingRequest.requestedStartDateString).format("MM/DD/YYYY"),
            token: userInfo().token,
            isEndMarker: false
        }

        dispatch(fieldingEdit(data))
            .unwrap()
            .then((res: any) => {
                if (res.status === 200) {
                    notificationSuccess("")
                    setSelectedAssign([])
                    getDetails()
                }
            })
            .catch((err: any) => {
                Swal.fire({
                    icon: "error",
                    title: "Failed!",
                    text: ""
                })
            })
    }

    const isPointInsideShape = (point: InterfaceMarkerPoint, polygon: InterfaceMarkerLine): boolean => {
        let betweenCount = 0
        for (let i = 0; i < polygon.length; i++) {
            for (let j = 0; j < polygon.length; j++) {
                if (i !== j) {
                    let latitude1: number = Math.min(polygon[i].latitude, polygon[j].latitude)
                    let latitude2: number = Math.max(polygon[i].latitude, polygon[j].latitude)
                    let longitude1: number = Math.min(polygon[i].longitude, polygon[j].longitude)
                    let longitude2: number = Math.max(polygon[i].longitude, polygon[j].longitude)
                    if (
                        point.latitude > latitude1 &&
                        point.latitude < latitude2 &&
                        point.longitude > longitude1 &&
                        point.longitude < longitude2
                    ) {
                        betweenCount = betweenCount + 1
                    }
                }
            }
        }
        return polygon.length > 2 && Boolean(betweenCount >= polygon.length)
    }

    const getDistanceMiles = (point1: InterfaceMarkerPoint, point2: InterfaceMarkerPoint) => {
        const R = 6371e3
        const φ1 = (point1.latitude * Math.PI) / 180
        const φ2 = (point2.latitude * Math.PI) / 180
        const Δφ = ((point2.latitude - point1.latitude) * Math.PI) / 180
        const Δλ = ((point2.longitude - point1.longitude) * Math.PI) / 180
        const a =
            Math.sin(Δφ / 2) * Math.sin(Δφ / 2) +
            Math.cos(φ1) * Math.cos(φ2) * Math.sin(Δλ / 2) * Math.sin(Δλ / 2)
        const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a))
        return ((R * c) / 1609.344).toFixed(2)
    }

    const fielderListitem = (fielder: InterfaceAccountFielderDetailData) => {
        return (
            <ListItem key={fielder.id} disablePadding>
                <HtmlTooltip
                    title={
                        <Box display="flex" sx={{ borderRadius: 2 }}>
                            {fielder.addressLocation !== undefined && (
                                <Fragment>
                                    {getDistanceMiles(
                                        {
                                            latitude: fielder.addressLocation.latitude,
                                            longitude: fielder.addressLocation.longitude
                                        },
                                        {
                                            latitude: parseFloat(fieldingRequest.firstMarkerLocation.split(",")[1]),
                                            longitude: parseFloat(fieldingRequest.firstMarkerLocation.split(",")[0])
                                        }
                                    )} Miles
                                    ,
                                </Fragment>
                            )}
                            {fielder.averageRatingString}{" "}
                            <Star
                                sx={{
                                    color: "gold",
                                    marginLeft: 1
                                }}
                            />
                        </Box>
                    }
                    placement="left-start"
                >
                    <ListItemButton
                        sx={{ padding: 0 }}
                        onMouseEnter={() => setFielderHoverId(fielder.id)}
                        onMouseLeave={() => setFielderHoverId("")}
                    >
                        <ListItemIcon>
                            <Checkbox
                                checked={fielderSelectedIds.includes(fielder.id)}
                                checkedIcon={<CheckBoxIcon sx={{ color: fielder.color }} />}
                                onChange={(e) => {
                                    if (e.target.checked) {
                                        setFielderSelectedIds([...fielderSelectedIds, fielder.id])
                                    } else {
                                        setFielderSelectedIds(fielderSelectedIds.filter((d) => d !== fielder.id))
                                    }
                                    setFielderDetailList(
                                        fielderDetailList.map((fielderDetail) => {
                                            let colorNotUsed = colors.filter(
                                                (c) => !fielderDetailList.map((f) => f.color).includes(c)
                                            )
                                            if (fielderDetail.id === fielder.id) {
                                                return {
                                                    ...fielderDetail,
                                                    color: e.target.checked
                                                        ? colorNotUsed.length > 0
                                                            ? colorNotUsed[0]
                                                            : randomColor()
                                                        : undefined
                                                }
                                            }
                                            return fielderDetail
                                        })
                                    )
                                }}
                            />
                        </ListItemIcon>
                        <Grid container spacing={2}>
                            <Grid item md={4} display={"flex"} style={{ paddingLeft: 0 }}>
                                {formatName(fielder.fullName)}
                                {fielder.skillTypeId && fielder.skillTypeId.includes(fieldingRequest.customTypeId) && (
                                    <Grade sx={{ color: "#fce82d" }} />
                                )}
                            </Grid>
                            <Grid item md={4} style={{ paddingLeft: "4px" }}>
                                <Typography
                                    marginLeft={2}
                                    color={"gray"}
                                    sx={{ whiteSpace: "nowrap", marginLeft: "4px" }}
                                >
                                    job active: {fielder.assignedFieldingRequestMarkerLocations.length}
                                </Typography>
                            </Grid>
                            <Grid
                                item
                                md={4}
                                style={{
                                    paddingLeft: "8px",
                                    position: "absolute",
                                    right: "16px"
                                }}
                            >
                                {fieldingRequest.fielderIds.includes(fielder.id) ? (
                                    <Button
                                        variant={fielderHoverId === fielder.id ? "contained" : "text"}
                                        color="success"
                                        onClick={() => handleChangeAssign(false, fielder.id)}
                                    >
                                        Assigned
                                    </Button>
                                ) : fieldingRequest.ftrFielderIds.includes(fielder.id) ? (
                                    <Button
                                        variant={fielderHoverId === fielder.id ? "contained" : "text"}
                                        color="warning"
                                        onClick={() => handleChangeAssign(false, fielder.id)}
                                    >
                                        {isAssignFtr ? "Pending" : "Selected"}
                                    </Button>
                                ) : (
                                    <Button
                                        variant={fielderHoverId === fielder.id ? "contained" : "text"}
                                        onClick={() => handleChangeAssign(true, fielder.id)}
                                    >
                                        Assign
                                    </Button>
                                )}
                            </Grid>
                        </Grid>
                    </ListItemButton>
                </HtmlTooltip>
            </ListItem>
        )
    }

    if (fieldingRequest.fieldingRequestMarkers.length === 0) {
        return <></>
    }

    const removeDuplicate = () => {
        let arrData = fieldingRequest.fieldingRequestMarkers.map((fielding) => fielding.customTypeString)
        arrData = arrData.filter((val, i) => arrData.indexOf(val) === i)
        return arrData.toString()
    }

    const enableSave = fieldingRequest.fielderIds.toString() !== fieldingRequestOriginal.fielderIds.toString() || SelectedAssign.length > 0

    return (
        <div className="fielding-assign" style={isMiniAssign ? {minHeight: "auto", maxHeight: "72vh", overflowX: "auto" } : {}}>
            {
                !isMiniAssign && (
                    <div className="container">
                        <Grid container spacing={1} padding={1}>
                            <Grid item xs={12}>
                                <Button startIcon={<ArrowBack/>} onClick={() => navigate(-1)}>
                                    Back
                                </Button>
                            </Grid>
                            <Grid item xs={12} md={2}>
                                <Typography variant="body1">
                                    Project <b>{fieldingRequest.project}</b>
                                </Typography>
                            </Grid>
                            <Grid item xs={12} md={2}>
                                <Typography variant="body1">Field Request :</Typography>
                                <Typography variant="body1">
                                    <b style={{display: "flex", flexWrap: "nowrap"}}>{fieldingRequest.name}</b>
                                </Typography>
                            </Grid>
                            <Grid item xs={12} md={2}>
                                <Typography variant="body1">
                                    Fielding Type{" "}
                                    <b>
                                        {removeDuplicate()}
                                    </b>
                                </Typography>
                            </Grid>
                            <Grid item xs={12} md={2}>
                                <Typography variant="body1">
                                    Due Date <b>{fieldingRequest.dueDateString}</b>
                                </Typography>
                            </Grid>
                            <Grid item xs={12} md={4}>
                                <Box display={"flex"} flexWrap={"wrap"} alignItems={"center"} gap={1}>
                                    <Box>
                                        <Typography variant="body1">
                                            <b>Fielding Date Range</b>
                                        </Typography>
                                    </Box>
                                    <Box display={"flex"} gap={1} flexWrap={"wrap"}>
                                        <TextField
                                            type="date"
                                            fullWidth={false}
                                            label="Start fielding"
                                            name="requestedStartDateString"
                                            value={moment(fieldingRequest.requestedStartDateString).format("YYYY-MM-DD")}
                                            onChange={handleChangeDate}
                                            sx={{ minWidth: "150px", marginTop: 1 }}
                                        />
                                        <TextField
                                            type="date"
                                            fullWidth={false}
                                            label="Complete fielding"
                                            name="requestedDueDateString"
                                            value={moment(fieldingRequest.requestedDueDateString).format("YYYY-MM-DD")}
                                            onChange={handleChangeDate}
                                            sx={{ minWidth: "150px", marginTop: 1 }}
                                        />
                                    </Box>
                                </Box>
                            </Grid>
                        </Grid>
                        <div className="map">
                            <ProjectAssignMap
                                fieldingRequest={fieldingRequest}
                                fielders={fielderDetailList}
                                fielderSelectedIds={fielderSelectedIds}
                            />
                            <Box sx={{margin: 2}} display={"flex"}>
                                <Button startIcon={<Circle sx={{color: "red"}}/>}>Field request</Button>
                                <Button startIcon={<ChangeHistory/>}>Start location</Button>
                                <Button startIcon={<Grade sx={{color: "#fce82d"}}/>}>Match skill type</Button>
                            </Box>
                        </div>
                    </div>
                )
            }

            <div className={`detail ${!isMobile && "scrollable"}`} style={isMiniAssign ? {width: "100%"} : {}}>
                <Box paddingBottom={3}>
                    <Box>
                        <FormControlLabel
                            control={<Checkbox checked={!isAssignFtr} onChange={(e) => handleChangeFtrQty(0)}/>}
                            label="Assign direct"
                        />
                    </Box>
                    <Box display={"flex"} flexWrap={"nowrap"}>
                        <FormControlLabel
                            control={<Checkbox checked={isAssignFtr} onChange={(e) => handleChangeFtrQty(1)}/>}
                            label="First to accept fielder"
                        />
                        <TextField
                            fullWidth={false}
                            type="number"
                            sx={{width: "150px"}}
                            label={<span style={{fontSize: "10px"}}>Number of fielder needed</span>}
                            value={fieldingRequest.ftrFielderQuantity}
                            onChange={(e) => {
                                if (parseInt(e.target.value) > 0) handleChangeFtrQty(parseInt(e.target.value))
                            }}
                        />
                    </Box>
                </Box>
                <Autocomplete
                    fullWidth
                    options={[{id: null, name: "All skill type"}, ...dataTypeList]}
                    getOptionLabel={(type) => type.name}
                    onChange={(e, value) => setTypeSelected(value?.id || null)}
                    value={
                        dataTypeList.filter((type) => type.id === typeSelected).length > 0
                            ? dataTypeList.filter((type) => type.id === typeSelected)[0]
                            : undefined
                    }
                    defaultValue={{id: null, name: "All skill type"}}
                    renderInput={(params) => <TextField {...params} label="Skill type"/>}
                />
                <br/>
                {!isMobile && (
                    <Box
                        display={"flex"}
                        flexGrow={1}
                        justifyContent={"flex-end"}
                        sx={{ width: "100%", paddingBottom: 2 }}
                    >
                        <Button fullWidth variant="contained" onClick={handleSubmit} disabled={!enableSave}>
                            Save
                        </Button>
                    </Box>
                )}
                <Card variant="outlined">
                    <CardHeader
                        title="Fielder in this work area"
                        titleTypographyProps={{ variant: "h6" }}
                        sx={{ padding: 1 }}
                    />
                    <List disablePadding style={{ overflow: "auto", maxHeight: "150px" }}>
                        {fielderDetailList
                            .filter((fielder) =>
                                isPointInsideShape(
                                    {
                                        latitude: parseFloat(fieldingRequest.firstMarkerLocation.split(",")[1]),
                                        longitude: parseFloat(fieldingRequest.firstMarkerLocation.split(",")[0])
                                    },
                                    fielder.workingAreaPolyData && fielder.workingAreaPolyData.includes("[")
                                        ? JSON.parse(fielder.workingAreaPolyData)
                                        : []
                                )
                            )
                            .map((fielder, i) => fielderListitem(fielder))}
                    </List>
                </Card>
                <br />

                <Card variant="outlined">
                    <CardHeader title="Fielder in state" titleTypographyProps={{ variant: "h6" }} sx={{ padding: 1 }} />
                    <List disablePadding style={{ overflow: "auto", maxHeight: "150px" }}>
                        {fielderDetailList
                            .filter(
                                (fielder) =>
                                    !isPointInsideShape(
                                        {
                                            latitude: parseFloat(fieldingRequest.firstMarkerLocation.split(",")[1]),
                                            longitude: parseFloat(fieldingRequest.firstMarkerLocation.split(",")[0])
                                        },
                                        fielder.workingAreaPolyData && fielder.workingAreaPolyData.includes("[")
                                            ? JSON.parse(fielder.workingAreaPolyData)
                                            : []
                                    ) && fielder.state === fieldingState
                            )
                            .map((fielder, i) => fielderListitem(fielder))}
                    </List>
                </Card>
                <br />
                <Card variant="outlined">
                    <CardHeader
                        title="Fielder out of state"
                        titleTypographyProps={{ variant: "h6" }}
                        sx={{ padding: 1 }}
                    />
                    <List disablePadding style={{ overflow: "auto", maxHeight: "150px" }}>
                        {fielderDetailList
                            .filter(
                                (fielder) =>
                                    !isPointInsideShape(
                                        {
                                            latitude: parseFloat(fieldingRequest.firstMarkerLocation.split(",")[1]),
                                            longitude: parseFloat(fieldingRequest.firstMarkerLocation.split(",")[0])
                                        },
                                        fielder.workingAreaPolyData && fielder.workingAreaPolyData.includes("[")
                                            ? JSON.parse(fielder.workingAreaPolyData)
                                            : []
                                    ) && fielder.state !== fieldingState
                            )
                            .map((fielder, i) => fielderListitem(fielder))}
                    </List>
                </Card>
                <br />
                <Divider />
                <br />
                <Autocomplete
                    fullWidth
                    multiple
                    readOnly
                    popupIcon={<></>}
                    options={fieldingRequest.fielderNames}
                    value={fieldingRequest.fielderNames}
                    renderInput={(params) => (
                        <TextField {...params} sx={{ borderTopRightRadius: "0px !important" }} label="Fielder" />
                    )}
                />
                {isMobile && (
                    <Button
                        sx={{ marginTop: 1 }}
                        fullWidth
                        variant="contained"
                        onClick={handleSubmit}
                        disabled={!enableSave}
                    >
                        Save
                    </Button>
                )}
            </div>
        </div>
    )
}

const HtmlTooltip = styled(({ className, ...props }: TooltipProps) => (
    <Tooltip {...props} classes={{ popper: className }} />
))
    (() => ({
        [`& .${tooltipClasses.tooltip}`]: {
            maxWidth: 220,
            fontSize: 14,
            border: "1px solid #dadde9",
            borderRadius: "8px",
            padding: "2px 12px "
        }
    }))
