import {
   Card,
   CardContent,
   CardHeader,
   Link,
   Table,
   TableBody,
   TableCell,
   TableContainer,
   TableHead,
   TableRow,
   Typography,
   useTheme,
} from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../store';
import { useEffect, useState } from 'react';
import moment from 'moment';
import { getDashboardFielderActive } from '../dashboard.api';
import CustomDatePicker from '../../../shared/customDatePicker/CustomDatePicker';
import { formatName } from '../../../shared/utils';
import CustomModal from '../../../shared/customModal/CustomModal';
import OntimeActiveFielderDetails from './OntimeActiveFielderDetails';

export default function OntimeActiveFielder() {
   const dispatch = useDispatch<any>();
   const theme = useTheme();

   const { dataFielderActive } = useSelector(
      (state: RootState) => state.dashboard
   );

   const [dateStart, setDateStart] = useState(
      moment().add(-7, 'days').format('YYYY-MM-DD')
   );
   const [dateEnd, setDateEnd] = useState(moment().format('YYYY-MM-DD'));
   const [targetFielderId, setTargetFielderId] = useState<string | undefined>(
      undefined
   );

   useEffect(() => {
      dispatch(
         getDashboardFielderActive({
            filterStartDate: dateStart,
            filterEndData: dateEnd,
         })
      );
   }, [dispatch, dateStart, dateEnd]);

   const dataSorted = [...dataFielderActive].sort((a, b) =>
      a.name.localeCompare(b.name)
   );

   return (
      <Card variant="outlined">
         <CustomModal
            open={targetFielderId !== undefined}
            onClose={() => setTargetFielderId(undefined)}
            title="Active Fielder Detail"
            size="md"
            fullHeight
            styles={{
               backgroundColor: '#f0f0f0',
            }}
            component={
               <OntimeActiveFielderDetails
                  dateStart={dateStart}
                  dateEnd={dateEnd}
                  targetFielderId={targetFielderId}
               />
            }
         />
         <CardHeader
            sx={{ minHeight: 88 }}
            title={
               <Typography variant="h5">
                  Jobs assign to fielder
                  <span
                     style={{
                        fontWeight: 'bold',
                        color: theme.palette.primary.main,
                     }}
                  >
                     {' '}
                     {dataFielderActive.length}
                  </span>
               </Typography>
            }
            action={
               <CustomDatePicker
                  dateStart={dateStart}
                  dateEnd={dateEnd}
                  setDateStart={setDateStart}
                  setDateEnd={setDateEnd}
               />
            }
         />
         <CardContent>
            <TableContainer sx={{ height: 315 }}>
               <Table stickyHeader>
                  <TableHead>
                     <TableRow>
                        <TableCell>Name</TableCell>
                        <TableCell>Assigned Job</TableCell>
                        <TableCell>Last Activity</TableCell>
                     </TableRow>
                  </TableHead>
                  <TableBody>
                     {dataSorted.map((item, index) => (
                        <TableRow key={index}>
                           <TableCell>{formatName(item.name)}</TableCell>
                           <TableCell
                              style={{ cursor: 'pointer' }}
                              onClick={() => setTargetFielderId(item.id)}
                           >
                              <Link>{item.totalAssignedJobs}</Link>
                           </TableCell>
                           <TableCell>{item.lastActivityDateString}</TableCell>
                        </TableRow>
                     ))}
                  </TableBody>
               </Table>
            </TableContainer>
         </CardContent>
      </Card>
   );
}
