import React, {useEffect, useState} from "react"
import {useDispatch, useSelector} from "react-redux"
import {approveExpense, deleteExpense, getExpenseDetail, getPersonalExpense} from "../expense.api"
import {RootState} from "../../../store"
import {
    Box,
    Button,
    CardContent,
    Container,
    IconButton,
    InputAdornment, Table, TableBody, TableCell, TableContainer, TableFooter, TableHead, TableRow,
    TextField,
    Typography,
    useTheme
} from "@mui/material"
import {resetDataDetail, setParameterGetData} from "../expense.reducer"
import {
    CheckCircleOutline,
    Close,
    HighlightOffOutlined,
    PauseCircleFilledOutlined,
    Search,
    Undo,
    Visibility,
    DeleteForever,
    Edit
} from "@mui/icons-material"
import CustomModal from "../../../shared/customModal/CustomModal"
import ExpenseReject from "./ExpenseReject"
import notificationSuccess from "../../../shared/notificationSuccess"
import Swal from "sweetalert2"
import ExpenseDetail from "./ExpenseDetail"
import Pagination from "../../../shared/Pagination"
import AccountResetPassword from "../../account/component/AccountResetPassword";
import AccountPasswordValidation from "../../account/component/AccountPasswordValidation";

export interface ExpenseMiniListProps {
    isEdit: boolean
}

export default function ExpenseMiniList({isEdit}: ExpenseMiniListProps) {

    const dispatch = useDispatch<any>()
    const theme = useTheme()

    const {dataList, dataTotal, parameterGetData} = useSelector((state: RootState) => state.expense)

    const [modalValidatePassword, setModalValidatePassword] = useState(false);
    const [search, setSearch] = useState('')
    const [idToReject, setIdToReject] = useState<string | null>(null)
    const [idToDetail, setIdToDetail] = useState<string | null>(null)
    const [idToDelete, setIdToDelete] = useState<string | null>(null)

    useEffect(() => {
        dispatch(getPersonalExpense(parameterGetData))
    }, [dispatch, parameterGetData])

    const statusList = [
        {name: 'Pending', value: 0, icon: PauseCircleFilledOutlined, color: 'warning'},
        {name: 'Paid', value: 1, icon: CheckCircleOutline, color: 'success'},
        {name: 'Rejected', value: 2, icon: HighlightOffOutlined, color: 'error'},
    ]

    const handleApprove = async (e: React.MouseEvent<HTMLButtonElement, MouseEvent>, id: string) => {
        e.stopPropagation()
        await submitApprove(id)
    }

    const handleReject = async (e: React.MouseEvent<HTMLButtonElement, MouseEvent>, id: string) => {
        e.stopPropagation()
        setIdToReject(id)
    }

    const submitApprove = async (id: string) => {
        await dispatch(approveExpense(id))
        await dispatch(getPersonalExpense(parameterGetData))
        await Swal.fire({
            title: 'Paid',
            icon: 'success',
            showConfirmButton: true,
            showDenyButton: true,
            confirmButtonText: 'OK',
            denyButtonText: 'Undo',
            reverseButtons: true,
            denyButtonColor: '#063058',
            confirmButtonColor: '#063058',
            showCloseButton: true,
            customClass: {
                closeButton: 'swal-close-button',
            }
        }).then(result => {
            if (result.isDenied) {
                // notificationSuccess("Billing request has been undone")
            }
        })
        if (idToDetail !== null) {
            dispatch(getExpenseDetail(idToDetail))
        }
    }

    const submitReject = async () => {
        setIdToReject(null)
        notificationSuccess("Billing request has been rejected")
        if (idToDetail !== null) {
            dispatch(getExpenseDetail(idToDetail))
        }
    }

    const handleDetail = (id: string) => {
        setIdToDetail(id)
    }

    const onDeleteClicked = async (id: string) => {
        Swal.fire({
            icon: "question",
            title: "Are you sure want to delete this expense?",
            showConfirmButton: true,
            showCancelButton: true,
            confirmButtonText: "Yes",
            cancelButtonText: "No"
        })
            .then((response) => {
                if (response.isConfirmed) {
                setIdToDelete(id)
                setModalValidatePassword(true)
                //     dispatch(deleteExpense(id)).unwrap()
                //         .then(() => {
                //             dispatch(getPersonalExpense(parameterGetData))
                //             notificationSuccess("Expense has been deleted")
                //             dispatch(resetDataDetail())
                //         })
                }
            })
    }

    return (
        <div className="project">
            <>
                <CustomModal
                    open={modalValidatePassword}
                    onClose={() => {
                        setModalValidatePassword(false)
                        setIdToDelete(null)
                    }}
                    title='Confirm Your Password'
                    size='sm'
                    component={
                        <AccountPasswordValidation
                            onClose={() => {
                                setModalValidatePassword(false)
                                setIdToDelete(null)
                            }}
                            onValidate={() => {
                                setModalValidatePassword(false)
                                setIdToDetail(null)
                                dispatch(deleteExpense(idToDelete as string)).unwrap()
                                    .then(() => {
                                        dispatch(getPersonalExpense(parameterGetData))
                                        notificationSuccess("Expense has been deleted")
                                        dispatch(resetDataDetail())
                                        setIdToDelete(null)
                                    })
                            }}
                        />
                    }
                />
                <CustomModal
                    size="md"
                    open={idToReject !== null}
                    onClose={() => setIdToReject(null)}
                    title="Reject Billing Request"
                    component={<ExpenseReject id={idToReject} onCancel={() => setIdToReject(null)}
                                              onSubmit={() => submitReject()}/>}
                />
                <CustomModal
                    size="lg"
                    open={idToDetail !== null}
                    onClose={() => setIdToDetail(null)}
                    title="Detail Billing Request"
                    component={idToDetail !== null &&
                        <ExpenseDetail id={idToDetail} onApprove={() => submitApprove(idToDetail)}
                                       onReject={() => setIdToReject(idToDetail)}
                                       onEdit={() => {
                                           dispatch(getExpenseDetail(idToDetail))
                                           setIdToDetail(null)
                                       }}
                                       onDelete={() => onDeleteClicked(idToDetail)}
                                       isMiniList={true}
                                       onClose={() => {
                                           setIdToDetail(null)
                                           dispatch(resetDataDetail())
                                       }}/>}
                />
            </>
            <Container maxWidth="xl" style={{padding: 0}}>
                <Box sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    px: 2,
                }}>
                    <Typography variant="h6">Expenses List</Typography>
                    {/*<TextField*/}
                    {/*    fullWidth={false}*/}
                    {/*    label='Search'*/}
                    {/*    InputProps={{*/}
                    {/*        startAdornment: (*/}
                    {/*            <InputAdornment position='start'>*/}
                    {/*                <Search/>*/}
                    {/*            </InputAdornment>*/}
                    {/*        ),*/}
                    {/*        endAdornment:*/}
                    {/*            parameterGetData.filter !== '' ? (*/}
                    {/*                <InputAdornment position='end'>*/}
                    {/*                    <IconButton*/}
                    {/*                        onClick={() => {*/}
                    {/*                            dispatch(*/}
                    {/*                                setParameterGetData({*/}
                    {/*                                    ...parameterGetData,*/}
                    {/*                                    skip: 0,*/}
                    {/*                                    filter: '',*/}
                    {/*                                })*/}
                    {/*                            );*/}
                    {/*                            setSearch('');*/}
                    {/*                        }}*/}
                    {/*                    >*/}
                    {/*                        <Close/>*/}
                    {/*                    </IconButton>*/}
                    {/*                </InputAdornment>*/}
                    {/*            ) : undefined,*/}
                    {/*    }}*/}
                    {/*    value={search}*/}
                    {/*    onChange={(e) => setSearch(e.target.value)}*/}
                    {/*    onKeyDown={(e) =>*/}
                    {/*        e.key === 'Enter' &&*/}
                    {/*        dispatch(*/}
                    {/*            setParameterGetData({*/}
                    {/*                ...parameterGetData,*/}
                    {/*                skip: 0,*/}
                    {/*                filter: search,*/}
                    {/*            })*/}
                    {/*        )*/}
                    {/*    }*/}
                    {/*/>*/}
                    {/*{search !== parameterGetData.filter && (*/}
                    {/*    <Button*/}
                    {/*        onClick={() =>*/}
                    {/*            dispatch(*/}
                    {/*                setParameterGetData({*/}
                    {/*                    ...parameterGetData,*/}
                    {/*                    skip: 0,*/}
                    {/*                    filter: search,*/}
                    {/*                })*/}
                    {/*            )*/}
                    {/*        }*/}
                    {/*    >*/}
                    {/*        Search*/}
                    {/*    </Button>*/}
                    {/*)}*/}
                    {
                        isEdit && (
                            <Button type="submit" variant="contained" color="primary"
                                    onClick={() => dispatch(resetDataDetail())}>Create Expense</Button>
                        )
                    }
                </Box>
                <Box sx={{
                    backgroundColor: '#ffffff'
                }}>
                    <CardContent>
                        <TableContainer sx={{maxHeight: 400}}>
                            <Table stickyHeader>
                                <TableHead>
                                    <TableRow>
                                        <TableCell>Expense ID</TableCell>
                                        <TableCell>Created Date</TableCell>
                                        <TableCell style={{textAlign: "right"}}>Amount</TableCell>
                                        <TableCell>Action</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {dataList.map((data) => (
                                        <TableRow key={data.id} className="hover"
                                                  sx={{":hover": {backgroundColor: '#80b8dd41'}}}>
                                            <TableCell>{data.docNumber}</TableCell>
                                            <TableCell style={{minWidth: 100}}>{data.createdDateString}</TableCell>
                                            <TableCell
                                                style={{textAlign: "right"}}>$ {data.amount.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</TableCell>
                                            {parameterGetData.status === 0
                                                ? (
                                                    <TableCell>
                                                        <Button color="primary" startIcon={<Visibility/>} onClick={() => handleDetail(data.id)}>View</Button>
                                                        <Button color="warning" startIcon={<Edit/>} onClick={() => dispatch(getExpenseDetail(data.id))}>Edit</Button>
                                                        <Button color="error" startIcon={<DeleteForever/>} onClick={() => onDeleteClicked(data.id)}>Delete</Button>
                                                    </TableCell>
                                                )
                                                : (
                                                    <TableCell>
                                                        <Button color="primary" startIcon={<Undo/>}>Undo</Button>
                                                    </TableCell>
                                                )
                                            }
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>

                    </CardContent>
                    <Box py={'10px'} px={'20px'}>
                        <div className='foot'>
                            <Pagination
                                skip={parameterGetData.skip}
                                take={parameterGetData.take}
                                dataTotal={dataTotal}
                                onChangePerPage={(value) =>
                                    dispatch(
                                        setParameterGetData({
                                            ...parameterGetData,
                                            skip: 0,
                                            take: value,
                                        })
                                    )
                                }
                                onBack={() =>
                                    dispatch(
                                        setParameterGetData({
                                            ...parameterGetData,
                                            skip: parameterGetData.skip - parameterGetData.take,
                                        })
                                    )
                                }
                                onNext={() =>
                                    dispatch(
                                        setParameterGetData({
                                            ...parameterGetData,
                                            skip: parameterGetData.skip + parameterGetData.take,
                                        })
                                    )
                                }
                            />
                        </div>
                    </Box>
                </Box>
            </Container>
        </div>
    )
}