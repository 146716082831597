import { KeyboardArrowLeft, KeyboardArrowRight } from "@mui/icons-material";
import { Box, Button, Card, CardContent, CardHeader, IconButton, Table, TableBody, TableCell, TableHead, TableRow, Typography, useTheme } from "@mui/material";
import moment, { Moment } from "moment";
import { useState } from "react";
import { useNavigate } from "react-router-dom";

export default function OntimeCalendar() {

    const theme = useTheme()
    const navigate = useNavigate()

    const dayNames = ["S", "M", "T", "W", "T", "F", "S"]

    const [year, setYear] = useState(moment().year())
    const [month, setMonth] = useState(moment().month())

    let weekList: Moment[][] = []
    const firstDayOfMonth = moment({ year, month: month, day: 1 })
    const firstWeekNumber = firstDayOfMonth.isoWeek();
    for (let i = firstWeekNumber; i <= firstWeekNumber + 5; i++) {
        const weekNumber = i
        const startDate = moment().year(year).week(weekNumber).startOf('week')
        let dateList = []
        for (let j = 0; j < 7; j++) {
            dateList.push(startDate.clone().add(j, "day"))
        }
        weekList.push(dateList)
    }

    const handleNext = () => {
        if (month < 11) {
            setMonth(month + 1)
        }
        else {
            setYear(year + 1)
            setMonth(0)
        }
    }

    const handlePrev = () => {
        if (month > 0) {
            setMonth(month - 1)
        }
        else {
            setYear(year - 1)
            setMonth(11)
        }
    }

    return (
        <Card variant="outlined" sx={{ display: "flex", flexDirection: "column", height: "100%" }}>
            <CardHeader title={(
                <Box display={"flex"} alignItems={"center"} gap={1}>
                    <Typography variant="h5" sx={{ display: "inline" }}>
                        {moment({ month, year }).format("MMMM YYYY")}
                    </Typography>
                    <Box>
                        <IconButton onClick={handlePrev}>
                            <KeyboardArrowLeft />
                        </IconButton>
                        <IconButton onClick={handleNext}>
                            <KeyboardArrowRight />
                        </IconButton>
                    </Box>
                </Box>
            )} action={(
                <Button variant="contained" onClick={() => navigate("/calendar")}>Details</Button>
            )} />
            <CardContent>
                <Table>
                    <TableHead>
                        <TableRow>
                            {dayNames.map((day, i) => (
                                <TableCell key={i} align="center" sx={{ padding: 1 }}>{day}</TableCell>
                            ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {weekList.map((dateList, i) => (
                            <TableRow key={i}>
                                {dateList.map((date, j) => {
                                    const isNow = moment().isSame(date, "day")
                                    return (
                                        <TableCell key={j} sx={{ padding: 1, verticalAlign: "top", opacity: date.month() === month ? 1 : 0.2 }}>
                                            <Typography align="center" borderRadius={1} fontWeight="bold" color={isNow ? theme.palette.primary.contrastText : undefined} bgcolor={isNow ? theme.palette.primary.main : undefined}>{date.date()}</Typography>
                                        </TableCell>
                                    )
                                })}
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </CardContent>
        </Card>
    )
}